import React, { useState, useEffect, useRef } from "react";
import { CustomSelect, CustomDateField } from "../../Forms/CustomAutocomplete";
import { Button } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  fetchEstadosCausacion,
  fetchDocumentosOpen,
} from "../../../services/OpenService";
import { useGlobalContext } from "../../../context/GlobalContext"; // Importar el contexto global

export const calculateTimeDifference = (docDate, docTime, currentDate) => {
  try {
    if (!docDate || !docTime) throw new Error("Fecha u hora inválida.");

    // Crear fecha completa del documento
    const [hour, minute, second] = docTime.split(":").map(Number);
    const [year, month, day] = docDate.split("-").map(Number);
    const documentDate = new Date(year, month - 1, day, hour, minute, second);

    if (isNaN(documentDate.getTime())) throw new Error("Fecha inválida.");

    if (currentDate < documentDate) {
      console.warn("La fecha/hora del documento es futura.");
      return null; // No calcular si el documento está en el futuro
    }

    // Inicializar tiempo total
    let totalMs = 0;

    // Iterar sobre cada día desde documentDate hasta currentDate
    let tempDate = new Date(documentDate);
    tempDate.setHours(0, 0, 0, 0); // Asegurarse de empezar desde el inicio del día

    while (tempDate < currentDate) {
      const dayOfWeek = tempDate.getDay(); // 0 = Domingo, 6 = Sábado

      // Ignorar fines de semana
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        const startOfDay = new Date(tempDate);
        const endOfDay = new Date(tempDate);
        endOfDay.setHours(23, 59, 59, 999);

        // Agregar tiempo solo para los días hábiles
        if (tempDate.toDateString() === documentDate.toDateString()) {
          // Primer día (parcial si es el día del documento)
          totalMs += Math.min(
            endOfDay - documentDate,
            currentDate - documentDate,
          );
        } else if (tempDate.toDateString() === currentDate.toDateString()) {
          // Último día (parcial si es hoy)
          totalMs += Math.min(
            currentDate - startOfDay,
            currentDate - documentDate,
          );
        } else {
          // Días completos
          totalMs += 24 * 60 * 60 * 1000; // 24 horas en ms
        }
      }

      // Pasar al siguiente día
      tempDate.setDate(tempDate.getDate() + 1);
    }

    // Calcular horas y minutos
    const totalHours = Math.floor(totalMs / (1000 * 60 * 60));
    const totalMinutes = Math.floor((totalMs % (1000 * 60 * 60)) / (1000 * 60));

    return {
      total: {
        hours: totalHours,
        minutes: totalMinutes,
      },
    };
  } catch (error) {
    console.warn("Error en cálculo de diferencia de tiempo:", error.message);
    return null;
  }
};

const formatDate = (date) => {
  return date
    ? date.toLocaleString("es-CO", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
    : "";
};

const Dates1 = ({ handleFormDataChange, onBuscar, onFilterDocuments }) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [formData, setFormData] = useState({
    fechaordenDesde: null,
    fechaordenHasta: null,
    estadOrden: "",
  });
  const [tipoDocumentoOptions, setTipoDocumentoOptions] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const documentosCargadosRef = useRef(false);
  const documentosPreviosRef = useRef(0);
  const { centro_costos } = useGlobalContext(); // Obtener los centro_costos del contexto global

  useEffect(() => {
    const fetchDatosIniciales = async () => {
      try {
        if (documentosCargadosRef.current) return;
        documentosCargadosRef.current = true;

        // Filtrar centros de costos
        const filteredCentrosCostos = centro_costos.filter((centro) => {
          if (centro.centro_costos_id === "Generico") {
            return centro.rol === "Líder";
          }
          return true;
        });

        // Incluir los centro_costos_id como parámetros en la llamada a la API
        const queryParams = filteredCentrosCostos
          .map(
            (centro) =>
              `centro_costos_id=${encodeURIComponent(centro.centro_costos_id)}`,
          )
          .join("&");

        const [documentosData, estados] = await Promise.all([
          fetchDocumentosOpen(`?${queryParams}`),
          fetchEstadosCausacion(),
        ]);

        const mappedDocumentos = documentosData.map((doc) => ({
          dirigir:
            doc.ultimo_centro_costos?.trim().toLowerCase() !== "generico"
              ? doc.ultimo_centro_costos
              : "Generico",
          emisor: doc.razon_social || "Desconocido",
          fecha: doc.fecha,
          cufe: doc.cufe,
          prefijo: doc.prefijo,
          consecutivo: doc.consecutivo,
          numfactura: `${doc.prefijo || ""}${doc.consecutivo || ""}`,
          valor: doc.valor,
          archivo: doc.archivo,
          estadoFactura: doc.estado_descripcion,
          centroCosto_id: doc.ultimo_centro_costos,
          hora: doc.hora,
          alerta: "",
          tramiteFactura:
            doc.estado_descripcion === "Documento recibido de la DIAN"
              ? "pendiente"
              : "completado",
          centroCosto: null,
        }));

        const mappedOptions = estados.map((estado) => ({
          value: estado.estado_id,
          label: estado.nombre,
          descripcion: estado.descripcion,
        }));

        setDocumentos(mappedDocumentos);
        setTipoDocumentoOptions(mappedOptions);

        const estadoPorDefecto = mappedOptions.find(
          (option) => option.label === "Facturas recibida DIAN",
        );

        if (estadoPorDefecto) {
          setFormData((prev) => ({
            ...prev,
            estadOrden: estadoPorDefecto.value,
          }));

          const documentosFiltrados = mappedDocumentos.filter(
            (doc) =>
              doc.estadoFactura?.trim().toLowerCase() ===
              estadoPorDefecto.descripcion?.trim().toLowerCase(),
          );

          onFilterDocuments?.(documentosFiltrados, mappedDocumentos);
        }
      } catch (error) {
        console.error("Error al cargar datos iniciales:", error);
      }
    };

    fetchDatosIniciales();
  }, [onFilterDocuments, centro_costos]); // Añadir centro_costos como dependencia

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (documentos.length > documentosPreviosRef.current) {
      documentos.forEach((doc) => {
        if (doc.fecha && doc.hora) {
          const tiempoTranscurrido = calculateTimeDifference(
            doc.fecha,
            doc.hora,
            currentDateTime,
          );

          if (tiempoTranscurrido) {
            const { total } = tiempoTranscurrido;
            doc.tiempoTranscurrido = total; // Agregar la información de tiempo al documento
          }
        }
      });

      documentosPreviosRef.current = documentos.length;
      setDocumentos([...documentos]); // Actualizamos el estado con los tiempos calculados
    }
  }, [documentos, currentDateTime]);

  const handleFieldChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    handleFormDataChange?.(field, value);
  };

  const handleBuscar = async () => {
    try {
      const fechaDesde = formData.fechaordenDesde
        ? new Date(formData.fechaordenDesde).toISOString().split("T")[0]
        : null;
      const fechaHasta = formData.fechaordenHasta
        ? new Date(formData.fechaordenHasta).toISOString().split("T")[0]
        : null;

      // Construir los parámetros de consulta
      const queryParams = new URLSearchParams();

      // Añadir centro_costos_id
      const filteredCentrosCostos = centro_costos.filter((centro) => {
        if (centro.centro_costos_id === "Generico") {
          return centro.rol === "Líder";
        }
        return true;
      });

      filteredCentrosCostos.forEach((centro) => {
        queryParams.append("centro_costos_id", centro.centro_costos_id);
      });

      // Añadir fechas si están presentes
      if (fechaDesde) {
        queryParams.append("fecha_desde", fechaDesde);
      }
      if (fechaHasta) {
        queryParams.append("fecha_hasta", fechaHasta);
      }

      // Añadir estado_id si está presente
      if (formData.estadOrden) {
        // Asumiendo que estadOrden contiene el ID del estado
        queryParams.append("estado", formData.estadOrden);
      }

      // Realizar la consulta con los filtros
      const documentosData = await fetchDocumentosOpen(
        `?${queryParams.toString()}`,
      );

      const mappedDocumentos = documentosData.map((doc) => ({
        dirigir:
          doc.ultimo_centro_costos?.trim().toLowerCase() !== "generico"
            ? doc.ultimo_centro_costos
            : "Generico",
        emisor: doc.razon_social || "Desconocido",
        fecha: doc.fecha,
        cufe: doc.cufe,
        prefijo: doc.prefijo,
        consecutivo: doc.consecutivo,
        numfactura: `${doc.prefijo || ""}${doc.consecutivo || ""}`,
        valor: doc.valor,
        archivo: doc.archivo,
        estadoFactura: doc.estado_descripcion,
        centroCosto_id: doc.ultimo_centro_costos,
        hora: doc.hora,
        alerta: "",
        tramiteFactura:
          doc.estado_descripcion === "Documento recibido de la DIAN"
            ? "pendiente"
            : "completado",
        centroCosto: null,
      }));

      const estadoSeleccionado = tipoDocumentoOptions.find(
        (opt) => opt.value === formData.estadOrden,
      );

      if (estadoSeleccionado) {
        const documentosFiltrados = mappedDocumentos.filter(
          (doc) =>
            doc.estadoFactura?.trim().toLowerCase() ===
            estadoSeleccionado.descripcion?.trim().toLowerCase(),
        );

        onFilterDocuments?.(documentosFiltrados, mappedDocumentos);
      } else {
        onFilterDocuments?.(mappedDocumentos, mappedDocumentos);
      }

      onBuscar?.(formData);
    } catch (error) {
      console.error("Error al buscar documentos:", error);
    }
  };

  return (
    <div className="orden-date1-section">
      <div className="two-column-grid">
        <p className="p-align-izq">
          <strong>Fecha: </strong> {formatDate(currentDateTime)}
        </p>
        <div className="generate-button-container-factint">
          <Button
            variant="contained"
            className="save-button"
            onClick={handleBuscar}
            sx={{ textTransform: "none" }}
          >
            <SaveAltIcon />
            Buscar
          </Button>
        </div>
      </div>
      <div className="three-column-grid">
        <CustomDateField
          id="fecha-desde"
          label="Fecha Factura (Desde)"
          value={formData.fechaordenDesde}
          onChange={(newValue) =>
            handleFieldChange("fechaordenDesde", newValue)
          }
        />
        <CustomDateField
          id="fecha-hasta"
          label="Fecha Factura (Hasta)"
          value={formData.fechaordenHasta}
          onChange={(newValue) =>
            handleFieldChange("fechaordenHasta", newValue)
          }
        />
        <CustomSelect
          id="estado-orden"
          label="Estado"
          value={formData.estadOrden}
          options={tipoDocumentoOptions}
          onChange={(e) => handleFieldChange("estadOrden", e.target.value)}
        />
      </div>
    </div>
  );
};

export default Dates1;
