import React, { useState, useEffect } from "react";
import { Box, Typography, Paper, CircularProgress } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { useGlobalContext } from "../context/GlobalContext";
import { fetchReporteFacturasGlobal } from "../services/OpenService";
import "../styles/components/alertStyle.css";
import NotificationList from "../hooks/ListarNotificacion";

const HomeWelcomeBoard = () => {
  const { user, area, isUserLoggedIn, userInfo, company, isLoadingUserInfo } =
    useGlobalContext();

  // Eliminamos "reporte" ya que no se usa
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartData, setChartData] = useState([]); // Solo mantenemos chartData

  // Efecto para cargar el reporte de facturas
  useEffect(() => {
    const obtenerReporte = async () => {
      try {
        const data = await fetchReporteFacturasGlobal();

        // Convertimos los datos del reporte a formato para PieChart
        const transformedData = data.data.map((factura, index) => ({
          id: index, // ID único para cada elemento
          value: factura.suma_valores, // Valor total de las facturas
          label: factura.razon_social || "Desconocido", // Nombre del proveedor
        }));

        setChartData(transformedData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    obtenerReporte();
  }, []);

  if (isLoadingUserInfo) {
    return (
      <Box className="container-welcome loading-container">
        <CircularProgress />
        <Typography ml={2}>Cargando información del usuario...</Typography>
      </Box>
    );
  }

  if (!isUserLoggedIn) {
    return (
      <Box className="container-welcome error-container">
        <CircularProgress />
        <Typography variant="h6">
          El usuario no está autenticado. Por favor, inicie sesión nuevamente.
        </Typography>
      </Box>
    );
  }

  return (
    <div className="container-welcomegen">
      <div className="container-welcome">
        <div className="container-welcome2">
          <div className="three-column-grid">
            <Typography variant="h5" gutterBottom align="center">
              Bienvenid@ <br />
              {user}
            </Typography>
            <Typography variant="body1" gutterBottom align="center">
              {area}
              <br />
              {company?.name}
            </Typography>
            <div className="centros-costos-container">
              <p>Centros de costo asociados:</p>

              {userInfo?.centros_costos?.map((centro, index) => (
                <Paper key={index} className="centro-costos" elevation={2}>
                  <Typography>
                    {centro.tipo_centro_costos} - {centro.rol}
                  </Typography>
                </Paper>
              ))}
            </div>
          </div>
        </div>
        <div className="notificationss-1">
          <NotificationList />
        </div>

        {/* 📊 Sección del gráfico PieChart dentro de graficaest */}
        <div className="graficaest">
          <div className="torta">
            {/*  <Typography variant="h6" gutterBottom>
            Distribución de Facturación por Proveedor
          </Typography>
 */}
            {loading ? (
              <CircularProgress />
            ) : error ? (
              <Typography color="error">
                Error al cargar el reporte: {error.message}
              </Typography>
            ) : (
              <PieChart
                series={[
                  {
                    data: chartData,
                    highlightScope: { fade: "global", highlight: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                    valueFormatter: (value) => `$${value.toLocaleString()}`, // Formato moneda
                  },
                ]}
                height={250} // Ajusta según tu diseño
              />
            )}
          </div>
        </div>

        <div className="prueba2"></div>
      </div>
    </div>
  );
};

export default HomeWelcomeBoard;
