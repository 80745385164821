import React, { useState, useEffect, useRef, useCallback } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import debounce from "lodash/debounce";
import { searchProveedor } from "../../../services/OrdenCompraService";

const DocumentoInputGroup = ({
  tipoDocumento,
  setTipoDocumento,
  tiposIdentificacion,
  nit,
  dv,
  handleNitChange,
  handleChange,
  onFieldChange,
  nitProveedor,
  onSearchResults, // Prop para enviar los resultados completos
  onTipoPersona,
  fetchedProveedorData, // Prop que contiene el JSON recibido
}) => {
  const executionCountRef = useRef(0);
  const initialTipoDocumentoSet = useRef(false);

  useEffect(() => {
    if (nitProveedor && executionCountRef.current < 2) {
      console.log(
        "NIT Proveedor recibido en DocumentoInputGroup:",
        nitProveedor,
      );
      handleNitChange({ target: { value: nitProveedor } });
      onFieldChange("numero", nitProveedor);
      executionCountRef.current += 1;
    }
  }, [nitProveedor, handleNitChange, onFieldChange]);

  // Efecto para mapear "tipo_documento" desde el JSON recibido, solo la primera vez
  useEffect(() => {
    if (
      fetchedProveedorData &&
      fetchedProveedorData.length > 0 &&
      !initialTipoDocumentoSet.current
    ) {
      const docType = fetchedProveedorData[0].asociado_negocio.tipo_documento;
      if (docType) {
        setTipoDocumento(docType);
        onFieldChange("tipoDocumento", docType);
        initialTipoDocumentoSet.current = true;
      }
    }
  }, [fetchedProveedorData, setTipoDocumento, onFieldChange]);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [proveedoresOptions, setProveedoresOptions] = useState([]);

  // --- NUEVA IMPLEMENTACIÓN DE VALIDACIÓN DEL NIT ---
  const validateNit = async () => {
    if (nit) {
      try {
        // Se utiliza searchProveedor para verificar si ya existe un proveedor con ese NIT
        const results = await searchProveedor(nit);
        if (results && results.length > 0) {
          setErrorMessage("El número de identificación ya existe.");
          setSuccessMessage("");
          const proveedor = results[0];
          console.log("Proveedor encontrado:", proveedor);
          const docType = proveedor.asociado_negocio.tipo_documento;
          setTipoDocumento(docType || "");
          onFieldChange("tipoDocumento", docType || "");
          // Aquí puedes agregar más onFieldChange para otros campos si lo requieres
        } else {
          setErrorMessage("");
          setSuccessMessage("Número de identificación válido.");
        }
      } catch (error) {
        setErrorMessage("Error al verificar el número.");
        setSuccessMessage("");
      }
    }
  };
  // -----------------------------------------------------

  const displayNit =
    nit ||
    (fetchedProveedorData && fetchedProveedorData.length > 0
      ? fetchedProveedorData[0].asociado_negocio.numero_identificacion
      : "");

  // --- USAMOS LA MISMA FUNCIÓN DE BÚSQUEDA CON DEBOUNCE ---
  const handleSearchProveedor = useCallback(
    debounce(async (query) => {
      if (!query || query.length < 6) {
        setProveedoresOptions([]);
        onFieldChange("senores", "");
        return;
      }
      console.log("Buscando proveedor con query:", query);
      try {
        const results = await searchProveedor(query);
        console.log("Resultados de la API:", results);
        if (onSearchResults) {
          onSearchResults(results);
        }
        if (results && results.length > 0 && onTipoPersona) {
          const tipoPersona = results[0].asociado_negocio.tipo_persona;
          onTipoPersona(tipoPersona);
        }
        const options = results.map((proveedor) => ({
          id: proveedor.asociado_de_negocio_id,
          value: proveedor.asociado_negocio.numero_identificacion,
          label:
            proveedor.asociado_negocio.razon_social ||
            [
              proveedor.asociado_negocio.primer_nombre,
              proveedor.asociado_negocio.primer_apellido,
            ]
              .filter(Boolean)
              .join(" "),
          data: proveedor,
        }));
        setProveedoresOptions(options);
      } catch (error) {
        console.error("Error al buscar proveedor:", error);
        setProveedoresOptions([]);
      }
    }, 500),
    [onFieldChange, onSearchResults, onTipoPersona],
  );
  // -----------------------------------------------------

  // --- MODIFICAMOS handleProveedorSelect PARA ENVIAR MÁS INFORMACIÓN ---
  const handleProveedorSelect = (option) => {
    console.log("Proveedor seleccionado:", option);
    const asociado = option.data.asociado_negocio;
    onFieldChange("nit", option.value);
    onFieldChange("tipoDocumento", asociado.tipo_documento || "");
    onFieldChange("razonSocial", asociado.razon_social || "");
    onFieldChange("correo", asociado.correo_electronico || "");
    onFieldChange("direccion", asociado.direccion || "");
    onFieldChange("telefono", asociado.telefono || "");
    onFieldChange("primerNombre", asociado.primer_nombre || "");
    onFieldChange("primerApellido", asociado.primer_apellido || "");
    onFieldChange("segundoApellido", asociado.segundo_apellido || "");
    setProveedoresOptions([]);
  };
  // -----------------------------------------------------

  // Creamos un arreglo fusionado de opciones para el Select
  const mergedTiposIdentificacion = React.useMemo(() => {
    const options = tiposIdentificacion || [];
    if (
      tipoDocumento &&
      !options.some((tipo) => tipo.codigo_dian === tipoDocumento)
    ) {
      return [
        ...options,
        { codigo_dian: tipoDocumento, descripcion: tipoDocumento },
      ];
    }
    return options;
  }, [tiposIdentificacion, tipoDocumento]);

  return (
    <div className="three-column-grid">
      <FormControl className="input-form-control" required>
        <InputLabel id="tipo-documento-label" className="input-label">
          Tipo de Documento
        </InputLabel>
        <Select
          labelId="tipo-documento-label"
          id="tipo-documento-juridica"
          value={tipoDocumento || ""}
          label="Tipo de Documento"
          required
          onChange={(e) => {
            setTipoDocumento(e.target.value);
            handleChange(e);
            onFieldChange("tipoDocumento", e.target.value);
          }}
          className="input-select"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {mergedTiposIdentificacion.map((tipo) => (
            <MenuItem key={tipo.codigo_dian} value={tipo.codigo_dian}>
              {tipo.descripcion}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div style={{ position: "relative" }}>
        <TextField
          id="numero-juridica"
          label="Número"
          type="text"
          inputProps={{ inputMode: "text", pattern: ".*" }}
          variant="outlined"
          className="input-textfield"
          required
          value={displayNit}
          onChange={(e) => {
            handleNitChange(e);
            onFieldChange("numero", e.target.value);
            // Llamamos a la búsqueda mientras se escribe si la longitud es suficiente
            if (e.target.value && e.target.value.length >= 6) {
              handleSearchProveedor(e.target.value);
            } else {
              setProveedoresOptions([]);
            }
          }}
          onBlur={validateNit}
          error={!!errorMessage}
          helperText={errorMessage || successMessage || " "}
        />
        {errorMessage === "El número de identificación ya existe." &&
          proveedoresOptions.length > 0 && (
            <div
              style={{
                position: "absolute",
                background: "white",
                border: "1px solid #ccc",
                width: "100%",
                zIndex: 1000,
              }}
            >
              {proveedoresOptions.map((option) => (
                <div
                  key={option.id}
                  style={{
                    padding: "8px",
                    cursor: "pointer",
                    borderBottom: "1px solid #eee",
                  }}
                  onClick={() => handleProveedorSelect(option)}
                >
                  {option.value} - {option.label}
                </div>
              ))}
            </div>
          )}
      </div>

      <TextField
        disabled
        id="dv-juridica"
        label="DV"
        variant="outlined"
        value={dv}
        className="input-textfield"
      />
    </div>
  );
};

export default DocumentoInputGroup;
