import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import {
  CustomAutocomplete,
  CustomTextField,
} from "../../Forms/CustomAutocomplete";
import useNitDV from "../../../hooks/useNitDV";
import DocumentoInputGroup from "../UpdateAsociados/EditDocumentInGroup";
import {
  fetchTiposIdentificacion,
  fetchCiiu,
} from "../../../services/apiService.js";

const PersonaNatural = ({
  handleChange,
  handleFormDataChange,
  nitProveedor,
  onTipoPersona,
  onSearchResults, // Función para recibir los resultados desde DocumentoInputGroup
  fetchedProveedorData, // JSON recibido completo desde UpdateAsociados
}) => {
  const { nit, dv, handleNitChange } = useNitDV();
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [tipoCiuu, setTipoCiuu] = useState(null);
  const [tiposIdentificacion, setTiposIdentificacion] = useState([]);
  const [ciiuOptions, setCiiuOptions] = useState([]);

  // Estado local para los campos editables
  const [localForm, setLocalForm] = useState({
    primerNombre: "",
    segundoNombre: "",
    primerApellido: "",
    segundoApellido: "",
    correo: "",
    ciiu: "",
  });

  // Cargar los datos iniciales desde el JSON recibido una sola vez y actualizar tanto el estado local como el formData del padre.
  useEffect(() => {
    if (fetchedProveedorData && fetchedProveedorData.length > 0) {
      console.log("JSON recibido en PersonaNatural:", fetchedProveedorData);
      const asociadoNegocio = fetchedProveedorData[0]?.asociado_negocio || {};
      const ciiuInicial = fetchedProveedorData[0]?.ciiu_id || "";

      // Actualizamos el estado local con los datos iniciales
      setLocalForm({
        primerNombre: asociadoNegocio.primer_nombre || "",
        segundoNombre: asociadoNegocio.segundo_nombre || "",
        primerApellido: asociadoNegocio.primer_apellido || "",
        segundoApellido: asociadoNegocio.segundo_apellido || "",
        correo: asociadoNegocio.correo_electronico || "",
        ciiu: ciiuInicial,
      });

      // Actualizamos el formData del padre (para que el JSON inicial también esté en el formData)
      handleFormDataChange("primerNombre", asociadoNegocio.primer_nombre || "");
      handleFormDataChange(
        "segundoNombre",
        asociadoNegocio.segundo_nombre || "",
      );
      handleFormDataChange(
        "primerApellido",
        asociadoNegocio.primer_apellido || "",
      );
      handleFormDataChange(
        "segundoApellido",
        asociadoNegocio.segundo_apellido || "",
      );
      handleFormDataChange("correo", asociadoNegocio.correo_electronico || "");
      handleFormDataChange("ciiu", ciiuInicial);
    }
  }, [fetchedProveedorData, handleFormDataChange]);

  useEffect(() => {
    if (nitProveedor) {
      console.log("NIT Proveedor recibido en PersonaNatural:", nitProveedor);
    }
  }, [nitProveedor]);

  useEffect(() => {
    const fetchData = async () => {
      const tipos = await fetchTiposIdentificacion();
      setTiposIdentificacion(tipos);
      const ciiuData = await fetchCiiu();
      setCiiuOptions(ciiuData);
    };
    fetchData();
  }, []);

  // Función para actualizar el estado local y notificar al padre
  const onLocalFieldChange = (field, value) => {
    setLocalForm((prev) => ({ ...prev, [field]: value }));
    handleFormDataChange(field, value);
  };

  // Efecto para actualizar el valor del Autocomplete de CIIU según el ciiu en el formData
  useEffect(() => {
    if (localForm.ciiu && ciiuOptions.length > 0) {
      const matchedOption = ciiuOptions.find(
        (ciiu) => String(ciiu.ciiu_id) === String(localForm.ciiu),
      );
      if (matchedOption) {
        setTipoCiuu({
          label: `${matchedOption.clase} - ${matchedOption.descripcion}`,
          value: matchedOption.ciiu_id,
        });
      } else {
        console.log("No se encontró una coincidencia en CIIU.");
        setTipoCiuu(null);
      }
    }
  }, [localForm.ciiu, ciiuOptions]);

  // Función para recibir resultados desde DocumentoInputGroup y reenviarlos
  const handleResultsFromDocument = (results) => {
    console.log("Resultados recibidos en PersonaNatural:", results);
    if (onSearchResults) {
      onSearchResults(results);
    }
  };

  const transformedOptions = ciiuOptions.map((ciiu) => ({
    label: `${ciiu.clase} - ${ciiu.descripcion}`,
    value: ciiu.ciiu_id,
  }));

  const selectedOption =
    tipoCiuu ||
    transformedOptions.find(
      (option) => String(option.value) === String(localForm.ciiu),
    ) ||
    null;

  return (
    <div className="personal-natural-section">
      <DocumentoInputGroup
        nitProveedor={nitProveedor}
        fetchedProveedorData={fetchedProveedorData}
        tipoDocumento={tipoDocumento}
        setTipoDocumento={setTipoDocumento}
        tiposIdentificacion={tiposIdentificacion}
        nit={nit}
        dv={dv}
        handleNitChange={handleNitChange}
        handleChange={handleChange}
        onFieldChange={onLocalFieldChange}
        onSearchResults={handleResultsFromDocument}
        onTipoPersona={onTipoPersona}
      />

      <div className="three-column-grid">
        <TextField
          id="primer-nombre-natural"
          label="Primer Nombre"
          variant="outlined"
          className="input-textfield"
          required
          value={localForm.primerNombre}
          onChange={(e) => onLocalFieldChange("primerNombre", e.target.value)}
        />
        <TextField
          id="segundo-nombre-natural"
          label="Segundo Nombre"
          variant="outlined"
          className="input-textfield"
          value={localForm.segundoNombre}
          onChange={(e) => onLocalFieldChange("segundoNombre", e.target.value)}
        />
        <CustomAutocomplete
          key={selectedOption ? selectedOption.value : "ciiu-autocomplete"}
          options={transformedOptions}
          value={selectedOption}
          onChange={(event, value) => {
            console.log("Nuevo valor seleccionado en Autocomplete:", value);
            setTipoCiuu(value || null);
            onLocalFieldChange("ciiu", value?.value || "");
          }}
          label="CIIU"
          required
        />
      </div>

      <div className="three-column-grid">
        <CustomTextField
          id="primer-apellido-natural"
          label="Primer Apellido"
          variant="outlined"
          className="input-textfield"
          required
          value={localForm.primerApellido}
          onChange={(e) => onLocalFieldChange("primerApellido", e.target.value)}
        />
        <CustomTextField
          id="segundo-apellido-natural"
          label="Segundo Apellido"
          variant="outlined"
          className="input-textfield"
          value={localForm.segundoApellido}
          onChange={(e) =>
            onLocalFieldChange("segundoApellido", e.target.value)
          }
        />
        <CustomTextField
          id="correo-natural"
          label="Correo"
          variant="outlined"
          className="input-textfield"
          required
          value={localForm.correo}
          onChange={(e) => onLocalFieldChange("correo", e.target.value)}
        />
      </div>
      <CustomTextField
        disabled
        id="ciiu-completo-natural"
        label="CIIU Completo"
        variant="outlined"
        value={tipoCiuu?.label || "Sin CIIU"}
        className="input-textfield"
      />
    </div>
  );
};

export default PersonaNatural;
