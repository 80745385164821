import React, { useState, useEffect } from "react";
import { fetchCentrosCostos } from "../../services/apiService"; // Asegúrate de que la ruta sea correcta

const OrdenVerify = ({ formData }) => {
  const [centrosCostos, setCentrosCostos] = useState([]);

  // Cargar los centros de costos desde la API
  useEffect(() => {
    const loadCentrosCostos = async () => {
      const data = await fetchCentrosCostos();
      setCentrosCostos(data); // Guarda los centros de costos en el estado
    };

    loadCentrosCostos();
  }, []);

  // Función para obtener la descripción del centro de costos
  const getCentroDescripcion = (idCentro) => {
    const centro = centrosCostos.find((c) => c.value === idCentro);
    return centro ? centro.label : "Centro de Costos no encontrado"; // Devuelve la descripción o un mensaje predeterminado
  };

  return (
    <div className="form-data">
      <h3 className="section-title-ver">Información del solicitante</h3>
      {formData.fechaHoraActual && (
        <p>
          <strong>Fecha:</strong>{" "}
          {new Date(formData.fechaHoraActual).toLocaleString("es-CO", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
        </p>
      )}

      {formData.usuario && (
        <p>
          <strong>Usuario:</strong> {formData.usuario}
        </p>
      )}
      {formData.cargo && (
        <p>
          <strong>Cargo:</strong> {formData.cargo}
        </p>
      )}

      <h3 className="section-title-ver">Detalles de la orden</h3>
      {formData.centroCostos && (
        <p>
          <strong>Centro de costos:</strong>{" "}
          {getCentroDescripcion(formData.centroCostos)}
        </p>
      )}
      {formData.nit && (
        <p>
          <strong>NIT:</strong> {formData.nit}
        </p>
      )}
      {formData.senores && (
        <p>
          <strong>Señor(es):</strong> {formData.senores}
        </p>
      )}
      {formData.sucursales && (
        <p>
          <strong>Sucursales:</strong> {formData.sucursales}
        </p>
      )}
      {formData.telefono && (
        <p>
          <strong>Teléfono:</strong> {formData.telefono}
        </p>
      )}

      <h3 className="section-title-ver">Información financiera</h3>
      {formData.metodoPagoDescripcion && (
        <p>
          <strong>Método de pago:</strong> {formData.metodoPagoDescripcion}
        </p>
      )}
      {formData.subtotal !== null && (
        <p>
          <strong>Subtotal:</strong> {formData.subtotal}
        </p>
      )}
      {formData.iva !== null && (
        <p>
          <strong>IVA:</strong> {formData.iva}%
        </p>
      )}
      {formData.total !== null && (
        <p className="red-text">
          <strong>Total:</strong> {formData.total}
        </p>
      )}

      <h3 className="section-title-ver">Fechas</h3>
      {formData.fechaAnteriorCompra && (
        <p>
          <strong>Fecha anterior de compra:</strong>{" "}
          {new Date(formData.fechaAnteriorCompra).toLocaleDateString("es-CO")}
        </p>
      )}
      {formData.fechaEntrega && (
        <p>
          <strong>Fecha de entrega:</strong>{" "}
          {new Date(formData.fechaEntrega).toLocaleDateString("es-CO")}
        </p>
      )}

      <h3 className="section-title-ver">Comentarios</h3>
      {formData.justificacion && (
        <p>
          <strong>Justificación:</strong> {formData.justificacion}
        </p>
      )}
      {formData.observaciones && (
        <p>
          <strong>Observaciones:</strong> {formData.observaciones}
        </p>
      )}

      <h3 className="section-title-ver">Detalles de la tabla</h3>
      {formData.items && formData.items.length > 0 ? (
        formData.items.map((row, index) => (
          <div key={row.id}>
            <p>
              <strong>Fila {index + 1}:</strong>
            </p>
            <p>
              <strong>Cantidad:</strong> {row.cant}
            </p>
            <p>
              <strong>Unidad de medida:</strong> {row.u_medida}
            </p>
            <p>
              <strong>Descripción:</strong> {row.description}
            </p>
            <p>
              <strong>Valor unitario:</strong> {row.price_unit}
            </p>
            <p>
              <strong>Valor total:</strong> {row.v_total}
            </p>
          </div>
        ))
      ) : (
        <p>No hay datos en la tabla.</p>
      )}
    </div>
  );
};

export default OrdenVerify;
