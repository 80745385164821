import React, { useState, useEffect, useRef } from "react";
import { CustomSelect, CustomDateField } from "../../Forms/CustomAutocomplete";
import { Button } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  fetchEstadosCausacion,
  fetchDocumentosOpen,
} from "../../../services/OpenService";
import { useGlobalContext } from "../../../context/GlobalContext"; // Importar el contexto global

export const calculateTimeDifference = (docDate, docTime, currentDate) => {
  try {
    if (!docDate || !docTime) throw new Error("Fecha u hora inválida.");

    const [hour, minute, second] = docTime.split(":").map(Number);
    const [year, month, day] = docDate.split("-").map(Number);
    const documentDate = new Date(year, month - 1, day, hour, minute, second);

    if (isNaN(documentDate.getTime())) throw new Error("Fecha inválida.");

    if (currentDate < documentDate) {
      console.warn("La fecha/hora del documento es futura.");
      return null; // No calcular si el documento está en el futuro
    }

    let totalMs = 0;
    let tempDate = new Date(documentDate);
    tempDate.setHours(0, 0, 0, 0);

    while (tempDate < currentDate) {
      const dayOfWeek = tempDate.getDay();

      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        const startOfDay = new Date(tempDate);
        const endOfDay = new Date(tempDate);
        endOfDay.setHours(23, 59, 59, 999);

        if (tempDate.toDateString() === documentDate.toDateString()) {
          totalMs += Math.min(
            endOfDay - documentDate,
            currentDate - documentDate,
          );
        } else if (tempDate.toDateString() === currentDate.toDateString()) {
          totalMs += Math.min(
            currentDate - startOfDay,
            currentDate - documentDate,
          );
        } else {
          totalMs += 24 * 60 * 60 * 1000;
        }
      }

      tempDate.setDate(tempDate.getDate() + 1);
    }

    const totalHours = Math.floor(totalMs / (1000 * 60 * 60));
    const totalMinutes = Math.floor((totalMs % (1000 * 60 * 60)) / (1000 * 60));

    return {
      total: {
        hours: totalHours,
        minutes: totalMinutes,
      },
    };
  } catch (error) {
    console.warn("Error en cálculo de diferencia de tiempo:", error.message);
    return null;
  }
};

const formatDate = (date) => {
  return date
    ? date.toLocaleString("es-CO", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
    : "";
};

const Dates1 = ({ handleFormDataChange, onBuscar, onFilterDocuments }) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [formData, setFormData] = useState({
    fechaordenDesde: null,
    fechaordenHasta: null,
    estadOrden: "",
  });
  const [tipoDocumentoOptions, setTipoDocumentoOptions] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const documentosCargadosRef = useRef(false);
  const documentosPreviosRef = useRef(0);
  const { centro_costos } = useGlobalContext(); // Obtener los centro_costos del contexto global

  useEffect(() => {
    const fetchDatosIniciales = async () => {
      try {
        if (documentosCargadosRef.current) return;
        documentosCargadosRef.current = true;

        // Obtener primero los estados para conocer el estado por defecto
        const estados = await fetchEstadosCausacion();
        const filteredOptions = estados
          .filter(
            (estado) =>
              estado.estado_id !== 1 &&
              estado.estado_id !== 3 &&
              estado.estado_id !== 4,
          )
          .map((estado) => ({
            value: estado.estado_id,
            label: estado.nombre,
            descripcion: estado.descripcion,
          }));

        setTipoDocumentoOptions(filteredOptions);

        const estadoPorDefecto = filteredOptions.find(
          (option) => option.label === "Proceso de aprobación",
        );

        // Filtrar centros de costos
        const filteredCentrosCostos = centro_costos.filter((centro) => {
          if (centro.centro_costos_id === "Generico") {
            return centro.rol === "Líder";
          }
          return true;
        });

        // Construir query params incluyendo estado por defecto
        const queryParams = new URLSearchParams();

        filteredCentrosCostos.forEach((centro) => {
          queryParams.append("centro_costos_id", centro.centro_costos_id);
        });

        if (estadoPorDefecto) {
          queryParams.append("estado", estadoPorDefecto.value);
          setFormData((prev) => ({
            ...prev,
            estadOrden: estadoPorDefecto.value,
          }));
        }

        // Realizar una única petición con los filtros
        const documentosData = await fetchDocumentosOpen(
          `?${queryParams.toString()}`,
        );
        const mappedDocumentos = documentosData.map((doc) => ({
          dirigir:
            doc.ultimo_centro_costos?.trim().toLowerCase() !== "generico"
              ? doc.ultimo_centro_costos
              : "Generico",
          emisor: doc.razon_social || "Desconocido",
          fecha: doc.fecha,
          cufe: doc.cufe,
          prefijo: doc.prefijo,
          consecutivo: doc.consecutivo,
          numfactura: `${doc.prefijo || ""}${doc.consecutivo || ""}`,
          valor: doc.valor,
          estadoFactura: doc.estado_descripcion,
          archivo: doc.archivo,
          centro_costos_id: doc.ultimo_centro_costos,
          hora: doc.hora,
          alerta: "",
          tramiteFactura:
            doc.estado_descripcion === "Proceso de aprobación"
              ? "pendiente"
              : "completado",
          centroCosto: null,
        }));

        setDocumentos(mappedDocumentos);

        if (estadoPorDefecto && onFilterDocuments) {
          onFilterDocuments(mappedDocumentos, mappedDocumentos);
        }
      } catch (error) {
        console.error("Error al cargar datos iniciales:", error);
      }
    };

    fetchDatosIniciales();
  }, [onFilterDocuments, centro_costos]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (documentos.length > documentosPreviosRef.current) {
      documentos.forEach((doc) => {
        if (doc.fecha && doc.hora) {
          const tiempoTranscurrido = calculateTimeDifference(
            doc.fecha,
            doc.hora,
            currentDateTime,
          );
          if (tiempoTranscurrido) {
            const { total } = tiempoTranscurrido;
            doc.tiempoTranscurrido = total; // Agregar la información de tiempo al documento
          }
        }
      });
      documentosPreviosRef.current = documentos.length;
      setDocumentos([...documentos]); // Actualizamos el estado con los tiempos calculados
    }
  }, [documentos, currentDateTime]);

  const handleFieldChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    handleFormDataChange(field, value);
  };

  const handleBuscar = async () => {
    try {
      const fechaDesde = formData.fechaordenDesde
        ? new Date(formData.fechaordenDesde).toISOString().split("T")[0]
        : null;
      const fechaHasta = formData.fechaordenHasta
        ? new Date(formData.fechaordenHasta).toISOString().split("T")[0]
        : null;

      // Construir los parámetros de consulta base con los centros de costo
      const baseParams = centro_costos
        .filter((centro) => {
          if (centro.centro_costos_id === "Generico") {
            return centro.rol === "Líder";
          }
          return true;
        })
        .map(
          (centro) =>
            `centro_costos_id=${encodeURIComponent(centro.centro_costos_id)}`,
        )
        .join("&");

      // Construir el resto de parámetros
      let additionalParams = [];
      if (fechaDesde) additionalParams.push(`fecha_desde=${fechaDesde}`);
      if (fechaHasta) additionalParams.push(`fecha_hasta=${fechaHasta}`);
      if (formData.estadOrden)
        additionalParams.push(`estado=${formData.estadOrden}`);

      // Combinar todos los parámetros
      const queryString = `?${baseParams}${additionalParams.length ? "&" + additionalParams.join("&") : ""}`;

      // Realizar la consulta con los filtros
      const documentosData = await fetchDocumentosOpen(queryString);

      const mappedDocumentos = documentosData.map((doc) => ({
        dirigir:
          doc.ultimo_centro_costos?.trim().toLowerCase() !== "generico"
            ? doc.ultimo_centro_costos
            : "Generico",
        emisor: doc.razon_social || "Desconocido",
        fecha: doc.fecha,
        cufe: doc.cufe,
        prefijo: doc.prefijo,
        consecutivo: doc.consecutivo,
        numfactura: `${doc.prefijo || ""}${doc.consecutivo || ""}`,
        valor: doc.valor,
        estadoFactura: doc.estado_descripcion,
        archivo: doc.archivo,
        centro_costos_id: doc.ultimo_centro_costos,
        hora: doc.hora,
        alerta: "",
        tramiteFactura:
          doc.estado_descripcion === "Proceso de aprobación"
            ? "pendiente"
            : "completado",
        centroCosto: null,
      }));

      const estadoSeleccionado = tipoDocumentoOptions.find(
        (opt) => opt.value === formData.estadOrden,
      );

      if (estadoSeleccionado) {
        const documentosFiltrados = mappedDocumentos.filter(
          (doc) =>
            doc.estadoFactura?.trim().toLowerCase() ===
            estadoSeleccionado.descripcion?.trim().toLowerCase(),
        );
        onFilterDocuments?.(documentosFiltrados, mappedDocumentos);
      } else {
        onFilterDocuments?.(mappedDocumentos, mappedDocumentos);
      }

      onBuscar?.(formData);
    } catch (error) {
      console.error("Error al buscar documentos:", error);
    }
  };

  return (
    <div className="orden-date1-section">
      <div className="two-column-grid">
        <p className="p-align-izq">
          <strong>Fecha: </strong> {formatDate(currentDateTime)}
        </p>
        <div className="generate-button-container-factint">
          <Button
            variant="contained"
            className="save-button"
            onClick={handleBuscar}
            sx={{ textTransform: "none" }}
          >
            <SaveAltIcon />
            Buscar
          </Button>
        </div>
      </div>

      <div className="three-column-grid">
        <CustomDateField
          id="fecha-desde"
          label="Fecha Factura (Desde)"
          value={formData.fechaordenDesde}
          onChange={(newValue) =>
            handleFieldChange("fechaordenDesde", newValue)
          }
        />
        <CustomDateField
          id="fecha-hasta"
          label="Fecha Factura (Hasta)"
          value={formData.fechaordenHasta}
          onChange={(newValue) =>
            handleFieldChange("fechaordenHasta", newValue)
          }
        />
        <CustomSelect
          id="estado-orden"
          label="Estado"
          value={formData.estadOrden}
          options={tipoDocumentoOptions}
          onChange={(e) => handleFieldChange("estadOrden", e.target.value)}
        />
      </div>
    </div>
  );
};

export default Dates1;
