import React, { useEffect, useState, useMemo, memo } from "react";
import { FileText } from "lucide-react";
import ReactVirtualizedTable from "../../ReactVirtualizedTable";
import PaginationComponent from "../../PaginationComponent";
import "../../../styles/components/tableFacturaInt.css";
import IconButton from "@mui/material/IconButton";
import { useAlert } from "../../../hooks/useAlert";
import { CustomAutocomplete } from "../../Forms/CustomAutocomplete";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import {
  fetchPresignedFileUrl,
  moverDocumentoCentroCostos,
  fetchCentrosOperacion,
} from "../../../services/OpenService";
import { calculateTimeDifference } from "./Dates1FacturasInt"; // Importar la función de cálculo de tiempo
import Loading from "../../../hooks/loading";

const TableFacturaInt = memo(({ documents, mappedDocumentos }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [centrosOptions, setCentrosOptions] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [localDocuments, setLocalDocuments] = useState([]); // Nuevo estado
  const { triggerAlert, Alert } = useAlert();
  const rowsPerPage = 10;
  const [loading, setLoading] = useState(false);

  // Actualizar localDocuments cuando documents cambie
  useEffect(() => {
    setLocalDocuments(documents);
    // Exponer la función de actualización al objeto window
    window.updateLocalDocuments = setLocalDocuments;

    // Limpieza al desmontar
    return () => {
      delete window.updateLocalDocuments;
    };
  }, [documents]);

  useEffect(() => {
    const fetchCentros = async () => {
      try {
        const centros = await fetchCentrosOperacion();
        const options = centros.map((centro) => ({
          label: centro.tipo_centro_costos,
          value: centro.centro_costos_id,
        }));
        setCentrosOptions(options);
      } catch (error) {
        console.error("Error al obtener los centros de operación:", error);
      }
    };

    fetchCentros();
  }, []);

  useEffect(() => {
    console.log("Documentos filtrados recibidos en la tabla:", documents);
    console.log(
      "Todos los documentos recibidos por defecto en la tabla desde el EndPoint del primer componente:",
      mappedDocumentos,
    );
  }, [mappedDocumentos, documents]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleAprobar = (cufe) => {
    setIsLoading(true);
    setTimeout(() => {
      const row = documents.find((row) => row.cufe === cufe);

      if (row) {
        // Crear objeto de datos que funcione para ambos casos
        const alertData = {
          cufe: row.cufe || "CUFE no definido",
          emisor: row.emisor || "Emisor no definido",
          estadoFactura: row.estadoFactura,
          orden: row.orden,
        };

        // Solo agregar productos si existen
        if (row.productos && Array.isArray(row.productos)) {
          setSelectedProducts([...row.productos]);
          alertData.products = [...row.productos];
        }

        triggerAlert("groupItems", alertData);
        console.log("Se pasó el CUFE:", row.cufe, "y el emisor:", row.emisor);
      }

      setIsLoading(false);
    }, 1000);
  };

  const handleRechazar = async (cufe) => {
    try {
      const tableElement = document.querySelector(".table-section");
      tableElement.classList.add("loading");

      // Mantener la alerta original
      triggerAlert("invoiceRejected", {});

      // Añadir la funcionalidad de devolver
      await handleMoveDocument(cufe, "Generico");

      // Actualizar el estado local del documento
      setLocalDocuments((prevDocs) => {
        return prevDocs.map((doc) => {
          if (doc.cufe === cufe) {
            return {
              ...doc,
              selectedValue: "Generico",
              isDisabled: true,
            };
          }
          return doc;
        });
      });
    } catch (error) {
      console.error("Error al rechazar el documento:", error);
    } finally {
      const tableElement = document.querySelector(".table-section");
      tableElement.classList.remove("loading");
    }
  };

  // Modificar getPaginatedRows para usar localDocuments en lugar de documents
  const getPaginatedRows = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return localDocuments.slice(startIndex, endIndex);
  }, [currentPage, rowsPerPage, localDocuments]);

  const getSemaforoColor = (rowData) => {
    const tiempoTranscurrido = calculateTimeDifference(
      rowData.fecha,
      rowData.hora,
      currentDateTime,
    );

    if (!tiempoTranscurrido) return "gris"; // Si no hay tiempo transcurrido, color gris

    const { total } = tiempoTranscurrido;
    const totalHours = total.hours;

    if (totalHours <= 24) return "verde";
    if (totalHours <= 48) return "amarillo";
    return "rojo";
  };

  const handleFetchPresignedUrl = async (fileKey) => {
    try {
      const tableElement = document.querySelector(".table-section");
      tableElement.classList.add("loading");

      const url = await fetchPresignedFileUrl(fileKey);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error al obtener la URL firmada:", error);
    } finally {
      const tableElement = document.querySelector(".table-section");
      tableElement.classList.remove("loading");
    }
  };

  const handleMoveDocument = async (cufe, centroCostosId) => {
    try {
      const tableElement = document.querySelector(".table-section");
      tableElement.classList.add("loading");

      const response = await moverDocumentoCentroCostos(cufe, centroCostosId);
      console.log("Respuesta de moverDocumentoCentroCostos:", response);

      if (centroCostosId === "Generico") {
        setLocalDocuments((prevDocs) =>
          prevDocs.filter((doc) => doc.cufe !== cufe),
        );
      }
    } catch (error) {
      console.error("Error al mover el documento al centro de costos:", error);
    } finally {
      const tableElement = document.querySelector(".table-section");
      tableElement.classList.remove("loading");
    }
  };

  const uniqueCentrosOptions = Array.from(
    new Map(centrosOptions.map((centro) => [centro.value, centro])).values(),
  );

  const formatValueWithThousandSeparator = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const customRenderers = useMemo(
    () => ({
      documento: (rowData) => (
        <IconButton
          onClick={() => handleFetchPresignedUrl(rowData.archivo)}
          title="Ver documento"
        >
          <FileText size={20} />
        </IconButton>
      ),
      tramiteFactura: (rowData) => {
        if (rowData.tramiteFactura === "completado") {
          return (
            <div className="button-container-tablereact2">
              <button
                type="button"
                className="tramitar-button green"
                onClick={() => handleAprobar(rowData.cufe)} // Cambia orden por cufe
              >
                Aprobar
              </button>
              <button
                type="button"
                className="tramitar-button red"
                onClick={() => handleRechazar(rowData.cufe)} // Cambia orden por cufe
              >
                Rechazar
              </button>
            </div>
          );
        } else {
          return <span style={{ color: "#757575" }}>Completado</span>;
        }
      },

      dirigir: (rowData) => {
        if (!Object.prototype.hasOwnProperty.call(rowData, "isDisabled")) {
          rowData.isDisabled = false; // Inicializa la propiedad si no existe
        }

        if (!Object.prototype.hasOwnProperty.call(rowData, "selectedValue")) {
          rowData.selectedValue = null; // Inicializa el valor seleccionado
        }

        const isGenerico = rowData.dirigir === "Generico";

        return isGenerico ? (
          <CustomAutocomplete
            options={uniqueCentrosOptions}
            value={
              rowData.selectedValue
                ? uniqueCentrosOptions.find(
                    (option) => option.value === rowData.selectedValue,
                  )
                : null
            }
            onChange={async (event, value) => {
              if (value) {
                rowData.selectedValue = value.value; // Guarda el valor seleccionado
                console.log(
                  `Centro de Costos seleccionado: ${value.label}`,
                  rowData,
                );
                await handleMoveDocument(rowData.cufe, value.value);
                rowData.isDisabled = true; // Deshabilita el componente
              }
            }}
            label={
              rowData.isDisabled
                ? uniqueCentrosOptions.find(
                    (option) => option.value === rowData.selectedValue,
                  )?.label || "Generico"
                : "Generico"
            }
            disabled={rowData.isDisabled} // Deshabilita si ya está seleccionado
          />
        ) : (
          <span style={{ color: "#757575", fontStyle: "italic" }}>
            {rowData.dirigir || "Sin información"}
          </span>
        );
      },

      alerta: (rowData) => (
        <div className={`semaforo ${getSemaforoColor(rowData)}`}>
          <span
            className={`semaforo-circulo ${getSemaforoColor(rowData)}`}
            title="Semáforo"
          ></span>
        </div>
      ),
      backfactura: (rowData) => (
        <IconButton
          onClick={async () => {
            setLoading(true); // Activar loading antes de iniciar el proceso

            try {
              const updatedRow = { ...rowData, centroCosto: null };
              console.log("Centro de Costos eliminado para: ", updatedRow);

              // Manda el valor predeterminado "Generico" y el cufe al handleMoveDocument
              await handleMoveDocument(rowData.cufe, "Generico");

              // Actualiza la UI para reflejar el cambio a "Generico"
              rowData.selectedValue = "Generico";
              rowData.isDisabled = true; // Deshabilita después de enviar a Generico
            } catch (error) {
              console.error("Error al mover el documento a Generico:", error);
            } finally {
              setLoading(false); // Desactivar loading después de completar la acción
            }
          }}
          title="Quitar Centro de Costos"
        >
          <AssignmentReturnIcon />
        </IconButton>
      ),
      valor: (rowData) => (
        <span>{formatValueWithThousandSeparator(rowData.valor)}</span>
      ),
    }),
    [currentDateTime, documents, centrosOptions],
  );

  return (
    <div
      className={`table-section up table-fade ${isLoading ? "loading" : ""}`}
    >
      {loading && <Loading />}
      <ReactVirtualizedTable
        columns={[
          { dataKey: "emisor", label: "Emisor", width: "auto" },
          { dataKey: "dirigir", label: "Dirigido", width: "auto" },
          { dataKey: "numfactura", label: "Factura", width: "auto" },
          { dataKey: "fecha", label: "Fecha", width: "auto" },
          { dataKey: "valor", label: "Valor", width: "auto" },
          { dataKey: "estadoFactura", label: "Estado", width: "auto" },
          { dataKey: "documento", label: "PDF", width: "8%" },
          { dataKey: "alerta", label: "Alerta", width: "8%" },
          { dataKey: "tramiteFactura", label: "Trámite", width: "9.6%" }, // Asegúrate de que está incluida
          { dataKey: "backfactura", label: "Retornar", width: "9%" },
        ]}
        data={getPaginatedRows}
        customRenderers={customRenderers}
      />

      <PaginationComponent
        totalItems={localDocuments.length} // Usar localDocuments aquí también
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onPageChange={handleChangePage}
      />
      <Alert selectedProducts={selectedProducts} />
    </div>
  );
});

TableFacturaInt.displayName = "TableFacturaInt";

export default TableFacturaInt;
