import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactVirtualizedTable from "../ReactVirtualizedTable";
import PaginationComponent from "../PaginationComponent";
import { useGlobalContext } from "../../context/GlobalContext";

const TableFactura = ({ filters }) => {
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const { isLoadingUserInfo } = useGlobalContext(); // Removemos centrosCostos ya que no se usa

  useEffect(() => {
    if (filters.data) {
      const mappedData = filters.data.map((item) => ({
        orden: item.numero_orden,
        fecha_generada: item.fecha_generada,
        fecha_entrega: item.fecha_entrega,
        centro_costos:
          item.tipo_centro_costos ||
          (typeof item.centro_costos === "object"
            ? item.centro_costos.tipo_centro_costos
            : item.centro_costos),
        estado: item.estado,
        id: item.numero_orden,
      }));
      setRows(mappedData);
    }
  }, [filters]);

  const getPaginatedRows = () => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return rows.slice(startIndex, endIndex);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    { dataKey: "orden", label: "Orden", width: "auto" },
    { dataKey: "fecha_generada", label: "Fecha generada", width: "auto" },
    { dataKey: "fecha_entrega", label: "Fecha de entrega", width: "auto" },
    { dataKey: "centro_costos", label: "Centro de costos", width: "auto" },
    { dataKey: "estado", label: "Estado", width: "auto" },
    {
      dataKey: "documento",
      label: "Documento",
      width: "auto",
      cellRenderer: ({ rowData }) => (
        <Link to={`/detalle-orden/${rowData.id}`} className="link">
          Ver Orden
        </Link>
      ),
    },
  ];

  if (isLoadingUserInfo) {
    return <div>Cargando información del usuario...</div>;
  }

  return (
    <div className="table-section up">
      <ReactVirtualizedTable
        columns={columns}
        data={getPaginatedRows()}
        customRenderers={{
          documento: (rowData) => (
            <Link to={`/detalle-orden/${rowData.id}`} className="link">
              Ver Orden
            </Link>
          ),
        }}
      />
      <PaginationComponent
        totalItems={rows.length}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onPageChange={handleChangePage}
      />
    </div>
  );
};

export default TableFactura;
