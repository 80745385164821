import React, { useState, useEffect } from "react";
import DocumentoInputGroup from "../UpdateAsociados/EditDocumentInGroup";
import {
  CustomAutocomplete,
  CustomTextField,
} from "../../Forms/CustomAutocomplete";
import useNitDV from "../../../hooks/useNitDV";
import {
  fetchTiposIdentificacion,
  fetchCiiu,
} from "../../../services/apiService";

const PersonaJuridica = ({
  handleChange,
  handleFormDataChange,
  nitProveedor,
  onTipoPersona,
  onSearchResults,
  fetchedProveedorData,
}) => {
  const { nit, dv, handleNitChange } = useNitDV();
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [tipoCiuu, setTipoCiuu] = useState(null);
  const [tiposIdentificacion, setTiposIdentificacion] = useState([]);
  const [ciiuOptions, setCiiuOptions] = useState([]);
  const [emailError, setEmailError] = useState(false);

  // Estado local para los campos editables
  const [localForm, setLocalForm] = useState({
    razonSocial: "",
    correo: "",
    ciiu: "",
  });

  // Cargar los datos iniciales desde fetchedProveedorData (solo una vez)
  useEffect(() => {
    if (fetchedProveedorData && fetchedProveedorData.length > 0) {
      console.log("JSON recibido en PersonaJuridica:", fetchedProveedorData);
      const asociadoNegocio = fetchedProveedorData[0]?.asociado_negocio || {};

      const razonSocial = asociadoNegocio.razon_social || "Sin Razón Social";
      const correo =
        asociadoNegocio.correo_electronico ||
        (asociadoNegocio.centro_operaciones &&
        asociadoNegocio.centro_operaciones.length > 0
          ? asociadoNegocio.centro_operaciones[0]
              .email_facturacion_electronica_proveedor?.[0] || "Sin Correo"
          : "Sin Correo");
      const ciiu = fetchedProveedorData[0]?.ciiu_id || "";

      // Actualizamos el estado local con los datos iniciales
      setLocalForm({
        razonSocial,
        correo,
        ciiu,
      });

      // Notificamos al componente padre (formData) de los valores iniciales
      handleFormDataChange("razonSocial", razonSocial);
      handleFormDataChange("correo", correo);
      handleFormDataChange("ciiu", ciiu);
    }
  }, [fetchedProveedorData, handleFormDataChange]);

  useEffect(() => {
    if (nitProveedor) {
      console.log("NIT Proveedor recibido en PersonaJuridica:", nitProveedor);
    }
  }, [nitProveedor]);

  useEffect(() => {
    const fetchData = async () => {
      const tipos = await fetchTiposIdentificacion();
      setTiposIdentificacion(tipos);
      const ciiuData = await fetchCiiu();
      setCiiuOptions(ciiuData);
    };
    fetchData();
  }, []);

  // Función de validación para el correo
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Función para actualizar el estado local y notificar al padre
  const onLocalFieldChange = (field, value) => {
    setLocalForm((prev) => ({ ...prev, [field]: value }));
    handleFormDataChange(field, value);
    if (field === "correo") {
      setEmailError(!validateEmail(value));
    }
  };

  // Efecto para mapear el valor de ciiu (local) a las opciones disponibles
  useEffect(() => {
    if (localForm.ciiu && ciiuOptions.length > 0) {
      const matchedOption = ciiuOptions.find(
        (ciiu) => String(ciiu.ciiu_id) === String(localForm.ciiu),
      );
      if (matchedOption) {
        setTipoCiuu({
          label: `${matchedOption.clase} - ${matchedOption.descripcion}`,
          value: matchedOption.ciiu_id,
        });
      } else {
        console.log("No se encontró una coincidencia en CIIU.");
        setTipoCiuu(null);
      }
    }
  }, [localForm.ciiu, ciiuOptions]);

  // Función para recibir resultados desde DocumentoInputGroup y reenviarlos
  const handleResultsFromDocument = (results) => {
    console.log("Resultados recibidos en PersonaJuridica:", results);
    if (onSearchResults) {
      onSearchResults(results);
    }
  };

  const transformedOptions = ciiuOptions.map((ciiu) => ({
    label: `${ciiu.clase} - ${ciiu.descripcion}`,
    value: ciiu.ciiu_id,
  }));

  const selectedOption =
    tipoCiuu ||
    transformedOptions.find(
      (option) => String(option.value) === String(localForm.ciiu),
    ) ||
    null;

  return (
    <div className="personal-juridica-section">
      <DocumentoInputGroup
        nitProveedor={nitProveedor}
        fetchedProveedorData={fetchedProveedorData}
        tipoDocumento={tipoDocumento}
        setTipoDocumento={setTipoDocumento}
        tiposIdentificacion={tiposIdentificacion}
        nit={nit}
        dv={dv}
        handleNitChange={handleNitChange}
        handleChange={handleChange}
        onFieldChange={onLocalFieldChange}
        onTipoPersona={onTipoPersona}
        onSearchResults={handleResultsFromDocument}
      />

      <div className="three-column-grid">
        <CustomTextField
          id="razon-social-juridica"
          label="Razón Social"
          value={localForm.razonSocial}
          onChange={(e) => onLocalFieldChange("razonSocial", e.target.value)}
          required
        />

        <CustomTextField
          id="correo-juridica"
          label="Correo"
          value={localForm.correo}
          onChange={(e) => onLocalFieldChange("correo", e.target.value)}
          required
          error={emailError}
          helperText={emailError ? "Por favor ingresa un correo válido" : ""}
        />

        <CustomAutocomplete
          key={selectedOption ? selectedOption.value : "ciiu-autocomplete"}
          options={transformedOptions}
          value={selectedOption}
          onChange={(event, value) => {
            console.log("Nuevo valor seleccionado en Autocomplete:", value);
            setTipoCiuu(value || null);
            onLocalFieldChange("ciiu", value?.value || "");
          }}
          label="CIIU"
          required
        />
      </div>

      <CustomTextField
        id="ciiu-completo-juridica"
        label="CIIU Completo"
        value={tipoCiuu?.label || "Sin CIIU"}
        disabled
      />
    </div>
  );
};

export default PersonaJuridica;
