import React, { useState, useEffect, useRef, useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import { Button, Modal } from "@mui/material";
import Dates3 from "../components/OrdenCompraForms/Dates1.js";
import "../styles/pages/OrdenCompra.pages.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useGlobalContext } from "../context/GlobalContext";
import OrdenVerify from "../components/OrdenCompraForms/OrdenVerify";
import {
  CustomTextField,
  CustomSelect,
  CustomTextarea,
  CustomDateField,
} from "../components/Forms/CustomAutocomplete.js";
import TableFactura from "../components/OrdenCompraForms/TableFactura.js";
import { Link } from "react-router-dom";
import {
  fetchFormasPago,
  enviarOrdenCompra,
} from "../services/OrdenCompraService";

const FormOrdenCompra = () => {
  const { user, area, company } = useGlobalContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [formasPagoOptions, setFormasPagoOptions] = useState([]);
  const [formData, setFormData] = useState({
    numeroOrden: "1234",
    centroCostos: "",
    rolCentroCostos: "",
    nit: "",
    senores: "",
    sucursales: "",
    telefono: "",
    metodoPago: "",
    subtotal: 0,
    iva: 19,
    total: 0,
    fechaAnteriorCompra: null,
    fechaEntrega: null,
    justificacion: "",
    observaciones: "",
    fechaAnteriordeCompra: "",
    items: [
      {
        id: 1,
        cant: 0,
        u_medida: "",
        description: "",
        price_unit: 0,
        v_total: 0,
      },
    ],
    usuario: user,
    cargo: area,
  });

  const formDateBasicRef = useRef(null);

  useEffect(() => {
    const loadFormasPago = async () => {
      try {
        const formasPago = await fetchFormasPago();
        const opciones = formasPago.map((forma) => ({
          value: forma.codigo,
          label: forma.descripcion,
        }));
        setFormasPagoOptions(opciones);
        if (
          formData.metodoPago &&
          !opciones.some((opt) => opt.value === formData.metodoPago)
        ) {
          handleFormDataChange("metodoPago", "");
        }
      } catch (error) {
        console.error("Error al cargar las formas de pago:", error);
      }
    };
    loadFormasPago();
  }, []);

  // Efecto para detectar problemas de token y recargar la página
  useEffect(() => {
    const checkAndReload = () => {
      const token = document.cookie.includes("token"); // Verifica si el token está en las cookies
      if (!token) {
        console.warn("Token ausente o no válido. Recargando la página...");
        window.location.reload(); // Fuerza un refresco de la página
      }
    };

    // Ejecutar validación solo si el usuario o la compañía no están definidos
    if (!user || !company) {
      checkAndReload();
    }
  }, [user, company]);

  const handleFormDataChange = (field, value) => {
    if (field === "fechaAnteriorCompra" && formData.fechaAnteriorCompra) {
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const transformFormDataForModal = () => {
    const transformedData = { ...formData };
    const metodoPago = formasPagoOptions.find(
      (opt) => opt.value === formData.metodoPago,
    );
    transformedData.metodoPagoDescripcion = metodoPago ? metodoPago.label : "";
    const centroCostos = user?.centros_costos?.find(
      (centro) => centro.centro_costos_id === formData.centroCostos,
    );
    transformedData.centroCostosDescripcion = centroCostos
      ? centroCostos.tipo_centro_costos
      : formData.centroCostos;
    return transformedData;
  };

  const transformedData = useMemo(
    () => transformFormDataForModal(),
    [formData, formasPagoOptions],
  );

  const isFormValid = () => {
    const requiredFields = [
      "centroCostos",
      "nit",
      "senores",
      "sucursales",
      "telefono",
      "metodoPago",
      "fechaEntrega",
      "justificacion",
    ];
    for (const field of requiredFields) {
      if (
        !formData[field] ||
        (typeof formData[field] === "string" && formData[field].trim() === "")
      ) {
        console.error(`Campo requerido faltante: ${field}`);
        return false;
      }
    }
    return true;
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Datos antes de validar:", formData);
    if (!isFormValid()) {
      alert(
        "Por favor, completa todos los campos requeridos antes de generar la orden.",
      );
      return;
    }
    try {
      console.log("Datos que se enviarán al backend:", formData);
      const response = await enviarOrdenCompra(formData, []);
      console.log("Orden de compra enviada exitosamente:", response);
      alert("Orden de compra enviada exitosamente.");
    } catch (error) {
      console.error("Error al enviar la orden de compra:", error);
      alert(
        "Error al enviar la orden de compra. Revisa la consola para más detalles.",
      );
    }
  };

  const formatNumber = (number) => {
    return number.toLocaleString("es-ES");
  };

  return (
    <div className="creation-form-container">
      <div className="form-header-ordencompra">
        <p>
          <strong>{company?.name}</strong>
        </p>
        <h1 className="form-title-ordencompra">
          Orden de compra y/o servicios
        </h1>
        <Link to="/HomeWelcomeBoard">
          <IconButton aria-label="back" className="back-button">
            <KeyboardReturnIcon />
          </IconButton>
        </Link>
      </div>
      <form className="form-content" onSubmit={handleSubmit}>
        <div ref={formDateBasicRef} className="form-date-basic---orden">
          <Dates3 handleFormDataChange={handleFormDataChange} />
          <TableFactura formData={formData} setFormData={setFormData} />
          <div className="factura-section">
            <div className="two-column-grid">
              <CustomSelect
                id="metodo-pago"
                label="Método de pago"
                value={formData.metodoPago || ""}
                options={formasPagoOptions}
                onChange={(e) =>
                  handleFormDataChange("metodoPago", e.target.value)
                }
                required
              />
              <CustomTextField
                id="subtotal"
                label="SUBTOTAL"
                value={formatNumber(formData.subtotal)}
                disabled
              />
            </div>
            <div className="two-column-grid">
              <CustomDateField
                id="fecha-anterior-compra"
                label="Fecha anterior de compra"
                value={formData.fechaAnteriorCompra || ""}
                disabled
              />
              <CustomTextField
                id="iva"
                label="IVA (%)"
                value={formData.iva}
                disabled
              />
            </div>
            <div className="two-column-grid">
              <CustomDateField
                id="fecha-entrega"
                label="Fecha de entrega"
                value={formData.fechaEntrega}
                onChange={(newValue) =>
                  handleFormDataChange("fechaEntrega", newValue)
                }
                minDate={new Date()}
                required
              />
              <CustomTextField
                id="total"
                label="TOTAL"
                value={formatNumber(formData.total)}
                disabled
              />
            </div>
            <div className="two-column-grid">
              <CustomTextarea
                id="justificacion"
                value={formData.justificacion}
                onChange={(value) =>
                  handleFormDataChange("justificacion", value)
                }
                placeholder="Escribe aquí la justificación..."
                minRows={5}
              />
              <CustomTextarea
                id="observaciones"
                value={formData.observaciones}
                onChange={(value) =>
                  handleFormDataChange("observaciones", value)
                }
                placeholder="Escribe aquí las observaciones..."
                minRows={5}
              />
            </div>
            <div className="two-column-grid">
              <p>
                <strong>Solicitado por: </strong> {user} <br />
                <strong>Cargo: </strong> {area}
              </p>
            </div>
          </div>
        </div>
        <div className="generate-button-container-orden">
          <Button
            variant="contained"
            className="save-button"
            sx={{ textTransform: "none" }}
            onClick={handleOpenModal}
          >
            <SaveAltIcon />
            Generar
          </Button>
        </div>
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <div
            className="modal-content"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "40%",
              background: "#ffffff",
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              overflow: "hidden",
            }}
          >
            <h2
              id="modal-title"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Verificación de datos del formulario
            </h2>
            <div
              className="modal-body"
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                padding: "10px 20px",
              }}
            >
              <OrdenVerify formData={transformedData} />
            </div>
            <div
              className="modal-footer"
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "10px 0",
              }}
            >
              <Button
                variant="outlined"
                style={{
                  color: "#007bff",
                  borderColor: "#007bff",
                  textTransform: "none",
                  width: "40%",
                }}
                onClick={handleCloseModal}
              >
                Editar
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#00CBA9",
                  color: "#fff",
                  textTransform: "none",
                  width: "40%",
                }}
                onClick={handleSubmit}
              >
                Guardar
              </Button>
            </div>
          </div>
        </Modal>
      </form>
    </div>
  );
};

export default FormOrdenCompra;
