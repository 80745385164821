import React, { useState, useEffect, useCallback } from "react";
import { CustomTextField, CustomSelect } from "../Forms/CustomAutocomplete";
import { useGlobalContext } from "../../context/GlobalContext";
import {
  searchProveedor,
  fetchUltimaOrdenCompra,
} from "../../services/OrdenCompraService";

const Dates3 = ({ handleFormDataChange }) => {
  const { userInfo } = useGlobalContext();
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [formData, setFormData] = useState({
    centroCostos: "",
    nit: "",
    senores: "",
    sucursales: "",
    telefono: "",
    fechaHoraActual: new Date(),
  });
  const [sucursalOptions, setSucursalOptions] = useState([]);
  const [telefonoOptions, setTelefonoOptions] = useState([]);
  const [proveedoresOptions, setProveedoresOptions] = useState([]);

  const centrosCostosOptions =
    userInfo?.centros_costos?.map((centro) => ({
      value: centro.centro_costos_id,
      label: `${centro.centro_costos_id} - ${centro.tipo_centro_costos} -${centro.rol}`,
      rol: centro.rol, // Asegúrate de incluir el rol
    })) || [];

  useEffect(() => {
    const timer = setInterval(() => {
      const newDate = new Date();
      setCurrentDateTime(newDate);
      onFieldChange("fechaHoraActual", newDate);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const handleSearchProveedor = useCallback(
    debounce(async (query) => {
      if (!query) {
        setProveedoresOptions([]);
        onFieldChange("senores", ""); // Limpia el campo "Señores"
        return;
      }

      console.log("Buscando proveedor con query:", query);
      try {
        const results = await searchProveedor(query);
        console.log("Resultados de la API:", results);

        const options = results.map((proveedor) => ({
          id: proveedor.asociado_de_negocio_id,
          value: proveedor.asociado_negocio.numero_identificacion,
          label: `${
            proveedor.asociado_negocio.razon_social ||
            [
              proveedor.asociado_negocio.primer_nombre,
              proveedor.asociado_negocio.primer_apellido,
            ]
              .filter(Boolean)
              .join(" ")
          }`,
          data: proveedor,
        }));

        setProveedoresOptions(options);
      } catch (error) {
        console.error("Error al buscar proveedor:", error);
        setProveedoresOptions([]);
      }
    }, 500),
    [],
  );

  const handleProveedorSelect = async (proveedor) => {
    if (!proveedor) return;

    const { asociado_negocio } = proveedor.data;

    const senores =
      asociado_negocio.razon_social ||
      [asociado_negocio.primer_nombre, asociado_negocio.primer_apellido]
        .filter(Boolean)
        .join(" ");

    const sucursalOptions =
      asociado_negocio.centro_operaciones?.map((co) => ({
        value: `${co.nombre_centro_operaciones} - ${co.direccion}`,
        label: `${co.nombre_centro_operaciones} - ${co.direccion}`,
      })) || [];

    const telefonoOptions =
      asociado_negocio.centro_operaciones?.[0]?.telefonos?.map((tel) => ({
        value: tel,
        label: tel,
      })) || [];

    // Actualizar los campos necesarios
    onFieldChange("proveedor", { asociado_de_negocio_id: proveedor.id });
    onFieldChange("senores", senores);
    onFieldChange("nit", proveedor.value);
    onFieldChange("metodoPago", asociado_negocio.forma_pago || "");
    onFieldChange("sucursales", sucursalOptions[0]?.value || "");
    onFieldChange("telefono", telefonoOptions[0]?.value || "");

    setSucursalOptions(sucursalOptions);
    setTelefonoOptions(telefonoOptions);
    setProveedoresOptions([]);

    // Consultar la fecha de la última orden de compra
    try {
      const ultimaFecha = await fetchUltimaOrdenCompra(proveedor.id);
      onFieldChange("fechaAnteriorCompra", ultimaFecha); // Actualizar el estado del formulario
      console.log("Fecha anterior de compra:", ultimaFecha);
    } catch (error) {
      console.error("Error al obtener la fecha anterior de compra:", error);
      onFieldChange("fechaAnteriorCompra", null);
    }

    // Logs para depuración
    console.log("Proveedor seleccionado:", {
      asociado_de_negocio_id: proveedor.id,
      nit: proveedor.value,
      razon_social: senores,
      metodoPago: asociado_negocio.forma_pago,
      sucursales: sucursalOptions,
      telefonos: telefonoOptions,
    });
  };

  const onFieldChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    handleFormDataChange(field, value);
  };

  const formatDate = (date) => {
    return date.toLocaleString("es-CO", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit" /* 
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit", */,
    });
  };

  return (
    <div className="orden-date1-section">
      <div className="two-column-grid">
        <p className="p-align-izq">
          <strong>Fecha: </strong> {formatDate(currentDateTime)}
        </p>
      </div>
      <div className="three-column-grid">
        <CustomSelect
          id="centro-costos"
          className="item-1"
          label="Centro de Costos"
          value={formData.centroCostos}
          options={centrosCostosOptions}
          onChange={(e) => {
            const selectedCentro = centrosCostosOptions.find(
              (centro) => centro.value === e.target.value,
            );
            if (selectedCentro) {
              // Actualiza tanto el ID como el rol
              onFieldChange("centroCostos", selectedCentro.value);
              onFieldChange("rolCentroCostos", selectedCentro.rol); // Nuevo campo para el rol
            }
          }}
          required
        />

        <div style={{ position: "relative" }}>
          <CustomTextField
            id="nit"
            label="Número de Identificación del Proveedor"
            value={formData.nit}
            onChange={(e) => {
              const query = e.target.value;
              console.log("Texto ingresado:", query);
              onFieldChange("nit", query);
              handleSearchProveedor(query);
            }}
            required
          />
          {proveedoresOptions.length > 0 && (
            <div
              style={{
                position: "absolute",
                background: "white",
                border: "1px solid #ccc",
                width: "100%",
                zIndex: 1000,
              }}
            >
              {proveedoresOptions.map((option) => (
                <div
                  key={option.id}
                  style={{
                    padding: "8px",
                    cursor: "pointer",
                    borderBottom: "1px solid #eee",
                  }}
                  onClick={() => handleProveedorSelect(option)}
                >
                  {option.value} - {option.label}
                </div>
              ))}
            </div>
          )}
        </div>
        <CustomTextField
          id="senores"
          label="Señores"
          className="item-2"
          value={formData.senores}
          disabled
        />
      </div>
      <div className="two-column-grid">
        <CustomSelect
          id="sucursales"
          label="Sucursal"
          value={formData.sucursales}
          options={sucursalOptions}
          onChange={(e) => onFieldChange("sucursales", e.target.value)}
          required
        />
        <CustomSelect
          id="telefono"
          label="Teléfono"
          value={formData.telefono}
          options={telefonoOptions}
          onChange={(e) => onFieldChange("telefono", e.target.value)}
          required
        />
      </div>
    </div>
  );
};

export default Dates3;
