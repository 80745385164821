import React, { useState, useRef, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom"; // Importa Link de react-router-dom
import { Button } from "@mui/material";
import PersonaJuridica from "../components/AsociadosForms/PersonaJuridica.js";
import PersonaNatural from "../components/AsociadosForms/PersonaNatural.js";
import Proveedor from "../components/AsociadosForms/Proveedor/Proveedor.js";
import FormVerify from "../hooks/FormVerify";
import "../styles/pages/Asociados.pages.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowDownIcon from "../hooks/ArrowFlotant.js";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import AsociadosVerify from "../components/AsociadosForms/AsociadosVerify.js";
import HomeWelcomeBoard from "./HomeWelcomeBoard"; // Importa tu componente
import { data as documentData } from "../components/AsociadosForms/Proveedor/TableAdjuntos.js";
import { fetchPaises, fetchCiudadesPorPais } from "../services/apiService";
import "../styles/stylesOficial.css";
import {
  enviarAsociadoNegocio,
  buscarProveedorPorNit,
} from "../services/apiService";
import { useGlobalContext } from "../context/GlobalContext";
import { useLocation } from "react-router-dom";

const Profile = () => {
  const [showSecondPhone, setShowSecondPhone] = useState(false);
  const [showArrowIcon, setShowArrowIcon] = useState(true);
  const [showFormVerify, setShowFormVerify] = useState(false);
  const [paises, setPaises] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const { company } = useGlobalContext();

  const [formData, setFormData] = useState({
    name: "",
    country: "",
    email: "",
    city: "",
    address: "",
    phone1: "",
    phone2: "",
    tipoDocumento: "",
    numero: "",
    dv: "",
    razonSocial: "",
    correo: "",
    telefono: "",
    ciiu: "",
    isEmpleado: false,
    isCliente: false,
    isProveedor: false,
    isNatural: true,
    isJuridica: false,
    isProveedorCertificado: false,
    isAutoretenedor: false,
    isAutoretenedorICA: false,
    isResponsableIVA: false,
    metodoPago: "",
    numeroResolucion: "",
    centroCostos: "",
    banco: "",
    tipoCuenta: "",
    tipoProveedor: "",
    claseProveedor: "",
    numeroCuenta: "",
    titularCuenta: "",
    adjuntos: {}, // Convertido a objeto para mapeo directo por id
  });
  const location = useLocation();
  const { nit_proveedor } = location.state || {}; // Recupera el nit_proveedor

  const proveedorRef = useRef(null);
  const formDateBasicRef = useRef(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (name, checked) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleFormDataChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleAdjuntosChange = (uploadedFiles) => {
    setFormData((prevData) => ({
      ...prevData,
      adjuntos: uploadedFiles,
    }));
  };

  const handleNaturalChange = () => {
    setFormData((prevData) => ({
      ...prevData,
      isNatural: !prevData.isNatural,
      isJuridica: prevData.isNatural,
    }));
  };

  const handleJuridicaChange = () => {
    setFormData((prevData) => ({
      ...prevData,
      isNatural: prevData.isJuridica,
      isJuridica: !prevData.isJuridica,
    }));
  };

  const isSaveEnabled =
    formData.isEmpleado || formData.isCliente || formData.isProveedor;
  const scrollToProveedor = () => {
    if (proveedorRef.current) {
      proveedorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setShowArrowIcon(false);
    }
  };
  useEffect(() => {
    const cargarPaises = async () => {
      try {
        const data = await fetchPaises();
        setPaises(data.map((pais) => ({ label: pais, value: pais })));
      } catch (error) {
        console.error("Error al cargar los países:", error);
      }
    };
    cargarPaises();
  }, []);
  // Manejar cambio de país
  const handleCountryChange = async (event, value) => {
    setFormData((prevData) => ({
      ...prevData,
      country: value?.value || "",
      city: "", // Reiniciar la ciudad cuando se selecciona un nuevo país
    }));

    if (value?.value) {
      try {
        const ciudadesData = await fetchCiudadesPorPais(value.value);
        // Agregar un id único a cada ciudad
        const ciudadesConId = ciudadesData.map((ciudad, index) => ({
          ...ciudad,
          id: `${ciudad.value}-${index}`,
        }));
        setCiudades(ciudadesConId);
      } catch (error) {
        console.error("Error al cargar las ciudades:", error);
        setCiudades([]);
      }
    } else {
      setCiudades([]);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowArrowIcon(formDateBasicRef.current.scrollTop === 0);
    };

    const formDateBasicElement = formDateBasicRef.current;
    formDateBasicElement.addEventListener("scroll", handleScroll);

    return () => {
      formDateBasicElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSave = (e) => {
    e.preventDefault();

    // Validar que los campos requeridos estén llenos
    const requiredFields = [
      "name",
      "tipoDocumento",
      "numero",
      "ciiu",
      ...(formData.isJuridica ? ["razonSocial"] : []), // Solo requerido para persona jurídica.
      "correo",
      "country",
      "email",
      "city",
      "address",
      "phone1",
      ...(formData.isProveedor
        ? [
            "metodoPago",
            "banco",
            "tipoCuenta",
            "tipoProveedor",
            "claseProveedor",
            "numeroCuenta",
            "titularCuenta",
          ]
        : []),
    ];

    const missingFields = requiredFields.filter(
      (field) => !formData[field] || formData[field].toString().trim() === "",
    );

    if (missingFields.length > 0) {
      alert(
        `Por favor completa todos los campos requeridos: ${missingFields.join(
          ", ",
        )}`,
      );
      return;
    }

    // Mostrar FormVerify
    setShowFormVerify(true);
  };

  const handleConfirmSave = async () => {
    try {
      // Enviar el formulario al backend
      const response = await enviarAsociadoNegocio(formData);
      alert("Datos enviados exitosamente");
      console.log("Respuesta del backend:", response);
      setShowFormVerify(false); // Cerrar el modal después de enviar
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
      alert("Error al enviar los datos. Revisa los campos.");
    }
  };

  // Memoize components to prevent unnecessary re-renders
  const personaNaturalComponent = useMemo(
    () => (
      <PersonaNatural
        handleChange={handleChange}
        handleFormDataChange={handleFormDataChange}
        nitProveedor={nit_proveedor} // Pasa el nit_proveedor como prop
      />
    ),
    [nit_proveedor],
  );

  useEffect(() => {
    const cargarDatosProveedor = async () => {
      if (formData.numero) {
        try {
          const proveedor = await buscarProveedorPorNit(formData.numero);

          if (proveedor) {
            if (proveedor.total === 0) {
              return; // Salir de la función si proveedor.total es 0
            }

            // Normalize country to match `paises` options
            const matchingCountry = paises.find(
              (pais) =>
                pais.label.toLowerCase() === proveedor.pais.toLowerCase(),
            );

            // Update cities list if the country is found
            if (matchingCountry) {
              const ciudadesData = await fetchCiudadesPorPais(
                matchingCountry.value,
              );
              setCiudades(ciudadesData);

              // Try matching city from API with the fetched cities
              const matchingCity = ciudadesData.find(
                (ciudad) => ciudad.value === proveedor.ciudad,
              );

              setFormData((prevData) => ({
                ...prevData,
                name: proveedor.razonSocialjuridico || "",
                address: proveedor.direccion || "",
                email: proveedor.correo || "",
                phone1: proveedor.telefono || "",
                country: matchingCountry.value || "",
                city: matchingCity ? matchingCity.value : "",
              }));
            } else {
              // If no matching country, reset city list
              setCiudades([]);
              setFormData((prevData) => ({
                ...prevData,
                country: "",
                city: "",
              }));
            }
          }
        } catch (error) {
          console.error("Error al cargar los datos del proveedor:", error);
        }
      }
    };

    cargarDatosProveedor();
  }, [formData.numero, paises]);

  const personaJuridicaComponent = useMemo(
    () => (
      <PersonaJuridica
        handleChange={handleChange}
        handleFormDataChange={handleFormDataChange}
        nitProveedor={nit_proveedor} // Pasa el nit_proveedor como prop
      />
    ),
    [nit_proveedor],
  );

  const proveedorComponent = useMemo(
    () => (
      <Proveedor
        parentRef={formDateBasicRef}
        handleFormDataChange={handleFormDataChange}
        handleAdjuntosChange={handleAdjuntosChange} // Pasar función para actualizar adjuntos
      />
    ),
    [],
  );
  const [showWelcomeBoard, setShowWelcomeBoard] = useState(false);

  if (showWelcomeBoard) {
    return (
      <HomeWelcomeBoard
        onClose={() => setShowWelcomeBoard(false)} // Vuelve a `Profile` cuando cierre
      />
    );
  }

  return (
    <div className="creation-form-container">
      <div className="form-header">
        <p>
          {" "}
          <strong>{company?.name}</strong>
        </p>
        <h1 className="form-title">
          Formulario creación de asociados de negocio
          {/* Aquí puedes usar el nit_proveedor para rellenar campos o lógica adicional */}
        </h1>

        <Link to="/HomeWelcomeBoard">
          <IconButton aria-label="back" className="back-button">
            <KeyboardReturnIcon />
          </IconButton>
        </Link>
      </div>
      <form className="form-content" onSubmit={handleSave}>
        <div ref={formDateBasicRef} className="form-date-basic">
          <div className="person-type">
            <span className="person-type-label">Persona:</span>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={formData.isNatural}
                onChange={handleNaturalChange}
              />
              <span className="checkbox-text">Natural</span>
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={formData.isJuridica}
                onChange={handleJuridicaChange}
              />
              <span className="checkbox-text">Jurídica</span>
            </label>
          </div>
          {formData.isNatural
            ? personaNaturalComponent
            : personaJuridicaComponent}
          <div className="sucursal-section">
            <h1 className="sucursal-title">Sucursal</h1>
            <div className="two-column-grid">
              <TextField
                id="name-sucursal"
                label="Nombre"
                variant="outlined"
                className="input-textfield"
                required
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {/* Campo Autocomplete para Países */}
              <Autocomplete
                className="input-form-control"
                disablePortal
                options={paises}
                value={
                  paises.find((pais) => pais.value === formData.country) || null // Busca el país en las opciones
                }
                onChange={handleCountryChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="País"
                    className="input-select"
                    required
                    InputLabelProps={{
                      className: "input-label",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      className: "input-form-control",
                    }}
                  />
                )}
              />
            </div>
            <div className="two-column-grid">
              <TextField
                id="suc-email"
                label="Correo"
                variant="outlined"
                className="input-textfield"
                required
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {/* Campo Autocomplete para Ciudades */}
              <Autocomplete
                className="input-form-control"
                disablePortal
                options={ciudades} // Lista de opciones con { label, value }
                value={
                  ciudades.find((ciudad) => ciudad.value === formData.city) ||
                  null // Busca por 'value'
                }
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    city: newValue ? newValue.value : "", // Almacena el 'value' en formData
                  });
                }}
                getOptionKey={(option) => option.id} // Usar el id único como key
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ciudad"
                    className="input-select"
                    required
                    InputLabelProps={{
                      className: "input-label",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      className: "input-form-control",
                    }}
                  />
                )}
              />
            </div>
            <div className="three-column-grid">
              <TextField
                id="direccion-sucursal"
                label="Dirección"
                variant="outlined"
                className="input-textfield item-1"
                required
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
              <div
                className={`phone-input-containervis ${
                  showSecondPhone ? "visible item-2" : "invisible item-2"
                }`}
              >
                <TextField
                  id="second-phone-profile"
                  label="Teléfono 2"
                  variant="outlined"
                  className="input-textfield"
                  type="text"
                  inputProps={{ maxLength: 15 }}
                  name="phone2"
                  onChange={handleChange}
                />
              </div>
              <div className="phone-input-container item-3">
                <TextField
                  id="phone-profile"
                  label="Teléfono"
                  variant="outlined"
                  className="input-textfield responsive-textf-tel"
                  required
                  type="text"
                  inputProps={{ maxLength: 15 }}
                  name="phone1"
                  value={formData.phone1}
                  onChange={handleChange}
                />
                <div className="add-phone-button-container">
                  <Fab
                    size="small"
                    sx={{
                      backgroundColor: "#FCB10B",
                      color: "white", // Cambia el color del icono si es necesario
                      "&:hover": { backgroundColor: "#E0A009" }, // Color más oscuro al hacer hover
                    }}
                    aria-label="add"
                    onClick={() => setShowSecondPhone(!showSecondPhone)}
                    className="add-phone-button"
                  >
                    <AddIcon />
                  </Fab>
                </div>
              </div>
            </div>
            {formData.isProveedor && showArrowIcon && (
              <div className="icon-arrowdown-aso" onClick={scrollToProveedor}>
                <ArrowDownIcon />
              </div>
            )}
          </div>
          {formData.isProveedor && (
            <div ref={proveedorRef}>{proveedorComponent}</div>
          )}
        </div>
        <div className="bottom-content">
          <div className="bottom-checkboxes">
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={formData.isEmpleado}
                onChange={(e) =>
                  handleCheckboxChange("isEmpleado", e.target.checked)
                }
              />
              <span className="checkbox-text">Empleado</span>
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={formData.isCliente}
                onChange={(e) =>
                  handleCheckboxChange("isCliente", e.target.checked)
                }
              />
              <span className="checkbox-text">Cliente</span>
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={formData.isProveedor}
                onChange={(e) =>
                  handleCheckboxChange("isProveedor", e.target.checked)
                }
              />
              <span className="checkbox-text">Proveedor</span>
            </label>
          </div>
        </div>
        <div className="save-button-container">
          <Button
            variant="contained"
            type="button"
            className="save-button"
            sx={{ textTransform: "none" }}
            onClick={handleSave} // Abre FormVerify
            disabled={!isSaveEnabled}
          >
            <SaveAltIcon />
            Guardar
          </Button>
        </div>

        {showFormVerify && (
          <FormVerify
            formDataContent={
              <AsociadosVerify
                formData={formData}
                documentData={documentData}
              />
            }
            onClose={() => setShowFormVerify(false)} // Cierra FormVerify
            onConfirm={handleConfirmSave} // Envía los datos al backend
          />
        )}
      </form>
    </div>
  );
};

export default Profile;
