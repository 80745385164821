import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// --- Contextos ---
import { AuthProvider } from "./context/AuthContext"; // Proveedor de autenticación
import { GlobalProvider } from "./context/GlobalContext"; // Proveedor de datos globales
import { DataProvider } from "./context/DataContext"; // Contexto de datos
import { WebSocketProvider } from "./context/websocket"; // WebSocketProvider
import { TableFacturaProvider } from "./context/TableFacturaContext"; // Proveedor del contexto de factura

// --- Componentes ---
import MenuBar from "./components/Menu"; // Barra de menú
import PrivateRoute from "./components/PrivateRoute"; // Ruta privada
import HomeWelcomeBoard from "./pages/HomeWelcomeBoard"; // Página de bienvenida
import DetalleOrden from "./components/OrdenCompraForms/DetalleOrden"; // Detalle de la orden de compra

// --- Páginas ---
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import FormCrearAsociados from "./pages/FormCrearAsociados";
import FormUpdateAsociados from "./pages/FormUpdateAsociados";
import Login from "./pages/Login";
import FormOrdenCompra from "./pages/FormOrdenCompra";
import ConsultarOrdenCompra from "./pages/ConsultarOrdenCompra";
import FormFacturasCausar from "./pages/FormFacturasCausar";
import FormFacturasInterna from "./pages/FormFacturasInterna";
import FormFacturasGeneral from "./pages/FormFacturasGeneral";

function App() {
  return (
    <AuthProvider>
      <DataProvider>
        <GlobalProvider>
          <TableFacturaProvider>
            <WebSocketProvider>
              <Router>
                <Routes>
                  {/* Rutas de autenticación */}
                  <Route path="/login" element={<Login />} />

                  {/* Rutas protegidas por PrivateRoute */}
                  <Route
                    path="/"
                    element={
                      <PrivateRoute>
                        <MenuBar />
                      </PrivateRoute>
                    }
                  >
                    {/* Rutas internas */}
                    <Route index element={<Home />} />
                    <Route path="Dashboard" element={<Dashboard />} />
                    <Route
                      path="HomeWelcomeBoard"
                      element={<HomeWelcomeBoard />}
                    />
                    <Route
                      path="FormCrearAsociados"
                      element={<FormCrearAsociados />}
                    />
                    <Route
                      path="FormFacturasCausar"
                      element={<FormFacturasCausar />}
                    />
                    <Route
                      path="FormUpdateAsociados"
                      element={<FormUpdateAsociados />}
                    />
                    <Route
                      path="FormOrdenCompra"
                      element={<FormOrdenCompra />}
                    />
                    <Route
                      path="FormFacturasGeneral"
                      element={<FormFacturasGeneral />}
                    />
                    <Route
                      path="FormFacturasInterna"
                      element={<FormFacturasInterna />}
                    />
                    <Route
                      path="ConsultarOrdenCompra"
                      element={<ConsultarOrdenCompra />}
                    />
                    <Route
                      path="/detalle-orden/:id"
                      element={<DetalleOrden />}
                    />
                  </Route>

                  {/* Ruta 404 */}
                  <Route path="*" element={<h1>Página no encontrada</h1>} />
                </Routes>
              </Router>
            </WebSocketProvider>
          </TableFacturaProvider>
        </GlobalProvider>
      </DataProvider>
    </AuthProvider>
  );
}

export default App;
