import React, { useState, useEffect } from "react";
import { CheckCircle } from "lucide-react";
import { ErrorOutline } from "@mui/icons-material";
import { fetchPresignedFileUrl } from "../../../../services/OpenService";
// Mueve `data` fuera del componente `TableAdjuntos`
export const data = [
  { id: 1, document: "RUT", link_up: "Link...", btn_adjunto: "" },
  {
    id: 2,
    document:
      "Certificado de Existencio y Representación Legal de la Persona Jurídica",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 3,
    document: "Cédula del Representante Legal",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 4,
    document: "Estados Financieros",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 5,
    document: "Certificación Bancaria",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 6,
    document: "Referencia Comercial",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 7,
    document: "Certificado SG-SST",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 8,
    document: "Contrato, Tarifario o Cotización y Portafolio de Servicios",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 9,
    document: "Plan de Contingencias",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 10,
    document: "Autorización Tratamiento de Datos Personales",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 11,
    document: "Hoja de vida Proveedor",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 12,
    document: "Acuerdo Comercial, Operativos y Seguridad",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 13,
    document: "Código de Ética y Conducta de Proveedores",
    link_up: "Link...",
    btn_adjunto: "",
  },
  { id: 14, document: "Circular 170", link_up: "Link...", btn_adjunto: "" },
  {
    id: 15,
    document:
      "Declaración de Compromiso Prevención, Mitigación en la Cadena de Suministro",
    link_up: "Link...",
    btn_adjunto: "",
  },
  {
    id: 16,
    document: "Acta de Entrega Documentos Asociado de Negocio - Proveedor",
    link_up: "Link...",
    btn_adjunto: "",
  },
];

const TableAdjuntos = ({ fetchedAdjuntos }) => {
  const [uploadedFiles, setUploadedFiles] = useState({});
  // Nuevo estado para guardar las URL firmadas
  const [presignedUrls, setPresignedUrls] = useState({});
  // Efecto para mostrar en consola los adjuntos recibidos
  useEffect(() => {
    console.log(
      "JSON QUE RECIBE ADJUNTOS TABLE fetchedAdjuntos en TableAdjuntos:",
      fetchedAdjuntos,
    );
  }, [fetchedAdjuntos]);
  // Efecto para obtener las URL firmadas para cada adjunto recibido
  useEffect(() => {
    if (fetchedAdjuntos && fetchedAdjuntos.length > 0) {
      fetchedAdjuntos.forEach(async (adj) => {
        try {
          // Se envía la ruta_archivo al API service y se obtiene la URL firmada
          const url = await fetchPresignedFileUrl(adj.ruta_archivo);
          setPresignedUrls((prev) => ({ ...prev, [adj.nombre_archivo]: url }));
        } catch (error) {
          console.error(
            "Error fetching presigned URL for",
            adj.ruta_archivo,
            error,
          );
        }
      });
    }
  }, [fetchedAdjuntos]);

  const handleFileChange = (event, rowId) => {
    event.stopPropagation();
    const file = event.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);

      // Usa el nombre del documento asociado como el nuevo nombre del archivo
      const documentName =
        data.find((doc) => doc.id === rowId)?.document || file.name;

      // Crea un nuevo archivo renombrado virtualmente
      const renamedFile = new File(
        [file],
        `${documentName}${file.name.substring(file.name.lastIndexOf("."))}`,
        {
          type: file.type,
        },
      );

      setUploadedFiles((prev) => ({
        ...prev,
        [rowId]: { name: documentName, url: fileURL, file: renamedFile }, // Usa el archivo renombrado
      }));
    }
  };

  const handleFileClick = (rowId) => {
    document.getElementById(`file-input-${rowId}`).click();
  };

  const customRenderers = {
    btn_adjunto: (row) => {
      let icon = null;

      // Lógica separada para íconos
      if (uploadedFiles[row.id]) {
        icon = (
          <CheckCircle
            size={20}
            className="icon-check"
            title="Archivo adjuntado"
          />
        );
      } else if (row.document === "RUT") {
        icon = (
          <ErrorOutline
            size={20}
            className="icon-error"
            title="Archivo obligatorio no adjuntado"
          />
        );
      }

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="file"
            style={{ display: "none" }}
            id={`file-input-${row.id}`}
            onChange={(e) => handleFileChange(e, row.id)}
          />
          <button
            className="attach-button"
            type="button"
            onClick={() => handleFileClick(row.id)}
          >
            Adjuntar archivo
          </button>
          {icon}
        </div>
      );
    },
    link_up: (row) => {
      // Si hay un archivo subido, se muestra ese enlace:
      if (uploadedFiles[row.id]) {
        return (
          <a
            href={uploadedFiles[row.id].url}
            target="_blank"
            rel="noopener noreferrer"
            className="link-cell"
          >
            {uploadedFiles[row.id].name}
          </a>
        );
      } else {
        // Si no hay archivo subido, buscamos en fetchedAdjuntos
        if (fetchedAdjuntos && fetchedAdjuntos.length > 0) {
          const fetchedFile = fetchedAdjuntos.find(
            (adj) => adj.nombre_archivo === row.document,
          );
          if (fetchedFile) {
            // Utilizamos la URL firmada obtenida previamente
            const presignedUrl = presignedUrls[fetchedFile.nombre_archivo];
            if (presignedUrl) {
              return (
                <a
                  href={presignedUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-cell"
                >
                  {fetchedFile.nombre_archivo}
                </a>
              );
            } else {
              return <span className="link-cell">Cargando URL...</span>;
            }
          }
        }
        return <span className="link-cell">Link...</span>;
      }
    },
  };

  // Columnas de la tabla
  const columns = [
    { width: 100, label: "Documentos", dataKey: "document" },
    { width: 100, label: "Archivo", dataKey: "link_up" },
    { width: 70, label: "Adjuntos", dataKey: "btn_adjunto" },
  ];

  return { customRenderers, columns, data, uploadedFiles, setUploadedFiles };
};

export default TableAdjuntos;
