import React, { useEffect } from "react";
import ReactVirtualizedTable from "../ReactVirtualizedTable";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const TableFactura = ({ formData, setFormData }) => {
  const handleInputChange = (id, field, value) => {
    const updatedTable = formData.items.map((row) =>
      row.id === id
        ? {
            ...row,
            [field]: value,
            ...(field === "cant" || field === "price_unit"
              ? {
                  v_total:
                    (field === "cant" ? value : row.cant) *
                    (field === "price_unit" ? value : row.price_unit),
                }
              : {}),
          }
        : row,
    );

    setFormData((prevData) => ({ ...prevData, items: updatedTable }));
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  const addNewRow = () => {
    const newRow = {
      id: formData.items.length + 1,
      cant: 0,
      u_medida: "",
      description: "",
      price_unit: 0,
      v_total: 0,
    };

    const updatedTable = [...formData.items, newRow];
    setFormData((prevData) => ({ ...prevData, items: updatedTable }));
  };

  const removeRow = (id) => {
    const updatedTable = formData.items.filter((row) => row.id !== id);
    setFormData((prevData) => ({ ...prevData, items: updatedTable }));
  };

  const calculateSubtotal = () => {
    return formData.items.reduce(
      (total, row) => total + (parseFloat(row.v_total) || 0),
      0,
    );
  };

  useEffect(() => {
    const subtotal = calculateSubtotal();
    const ivaValue = (subtotal * formData.iva) / 100;
    const total = subtotal + ivaValue;
    setFormData((prev) => ({ ...prev, subtotal, total }));
  }, [formData.items, formData.iva]);

  const columns = [
    { dataKey: "cant", label: "Cantidad", width: "auto" },
    { dataKey: "u_medida", label: "Unidad de medida", width: "auto" },
    { dataKey: "description", label: "Descripción", width: "auto" },
    {
      dataKey: "price_unit",
      label: "Valor unitario",
      numeric: true,
      width: "auto",
    },
    { dataKey: "v_total", label: "Valor total", numeric: true, width: "auto" },
    { dataKey: "actions", label: "", width: 60 },
  ];

  const customRenderers = {
    cant: (row) => (
      <TextField
        type="number"
        value={row.cant}
        label="Cantidad"
        variant="standard"
        size="small"
        onChange={(e) =>
          handleInputChange(row.id, "cant", parseInt(e.target.value, 10) || 0)
        }
        onKeyDown={handleKeyDown}
      />
    ),
    u_medida: (row) => (
      <TextField
        type="text"
        value={row.u_medida}
        label="Unidad de Medida"
        variant="standard"
        size="small"
        onChange={(e) => handleInputChange(row.id, "u_medida", e.target.value)}
      />
    ),
    description: (row) => (
      <TextField
        type="text"
        value={row.description}
        label="Descripción"
        variant="standard"
        size="small"
        onChange={(e) =>
          handleInputChange(row.id, "description", e.target.value)
        }
      />
    ),
    price_unit: (row) => (
      <TextField
        type="number"
        value={row.price_unit}
        label="Valor Unitario"
        variant="standard"
        size="small"
        onChange={(e) =>
          handleInputChange(
            row.id,
            "price_unit",
            parseFloat(e.target.value) || 0,
          )
        }
        onKeyDown={handleKeyDown}
      />
    ),
    v_total: (row) => (
      <TextField
        type="number"
        value={row.v_total}
        label="Valor Total"
        variant="standard"
        size="small"
        disabled
      />
    ),
    actions: (row) => (
      <IconButton
        color="error"
        onClick={() => removeRow(row.id)}
        title="Eliminar fila"
      >
        <RemoveCircleIcon />
      </IconButton>
    ),
  };

  return (
    <div className="table-section-mq">
      <Button
        variant="contained"
        onClick={addNewRow}
        sx={{ textTransform: "none" }}
        className="save-button mq2"
      >
        Agregar Item
        <AddCircleOutlineIcon />
      </Button>
      <div className="table-section">
        {formData.items.length > 0 ? (
          <ReactVirtualizedTable
            columns={columns}
            data={formData.items}
            customRenderers={customRenderers}
          />
        ) : (
          <p>No hay filas. Agrega una nueva fila para comenzar.</p>
        )}
      </div>
    </div>
  );
};

export default TableFactura;
