import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import {
  Handshake,
  HandCoins,
  LogOut,
  ChevronDown,
  CircleUser,
  Bell,
} from "lucide-react";
import { useAuth } from "../context/AuthContext";
import homeIcon from "../styles/logoOfi/Logo_ICON_Window.png";
import "../styles/components/stylesMenu.css";
import Loading from "../hooks/loading.js";
import { useGlobalContext } from "../context/GlobalContext";
import Badge from "@mui/material/Badge";
import { useWebSocket } from "../context/websocket";

const MenuBar = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [isDashboardOpen, setDashboardOpen] = useState(false);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [isOverviewOpen, setOverviewOpen] = useState(false);
  const [isViewProfileOpen, setViewProfileOpen] = useState(false);
  const [isFacturasOpen, setFacturasOpen] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, setCompany, company, setIsCompanySelected } =
    useGlobalContext();
  const { messages, readNotifications } = useWebSocket();

  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);

  const handleLogout = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await logout();
      if (response && response.ok) {
        navigate("/login");
      } else {
        console.error(
          "Logout failed with status:",
          response ? response.status : "unknown",
        );
      }
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleDashboard = () => {
    setProfileOpen(false);
    setOverviewOpen(false);
    setDashboardOpen(!isDashboardOpen);
  };

  const toggleProfile = () => {
    setDashboardOpen(false);
    setViewProfileOpen(false);
    setProfileOpen(!isProfileOpen);
    if (company) setProfileOpen(!isProfileOpen);
  };

  const toggleFacturas = () => {
    setFacturasOpen(!isFacturasOpen);
  };

  const handleMouseEnter = () => {
    setExpanded(true);
  };

  const handleMouseLeave = () => {
    setExpanded(false);
    setDashboardOpen(false);
    setProfileOpen(false);
    setOverviewOpen(false);
    setViewProfileOpen(false);
    setFacturasOpen(false);
  };

  const handleResetCompany = () => {
    setCompany("");
    localStorage.removeItem("selectedCompany");
    setIsCompanySelected(false);
  };

  useEffect(() => {
    const unread = Object.entries(messages).some(
      ([id]) => !readNotifications[id],
    );
    setHasUnreadNotifications(unread);
  }, [messages, readNotifications]);

  return (
    <div className="layout">
      {loading && <Loading />}
      <div
        className={`side-menu ${isExpanded ? "expanded" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="menu-content">
          <div className="icon-container">
            <Link to="/" className="home-link" onClick={handleResetCompany}>
              <img src={homeIcon} className="home-icon" alt="Home" />
            </Link>
          </div>

          <button
            className={`custom-button ${!isExpanded ? "collapsed" : ""}`}
            onClick={() => {}}
          >
            <CircleUser
              className={`item-icon ${!isExpanded ? "collapsed" : ""}`}
            />
            {isExpanded && <span className="item-text">{user}</span>}
          </button>

          <div className="menu-items">
            <div
              className={`menu-item ${!company ? "disabled" : ""}`}
              onClick={company ? toggleDashboard : null}
            >
              <Handshake className="item-icon" />
              <span className="item-text">Asociados de Negocio</span>
              <ChevronDown
                className={`chevron ${isDashboardOpen ? "open" : ""}`}
              />
            </div>

            {isDashboardOpen && (
              <div className="submenu">
                <div
                  className="submenu-item"
                  onClick={() => company && setOverviewOpen(!isOverviewOpen)}
                >
                  <span>Gestión</span>
                  <ChevronDown
                    className={`chevron ${isOverviewOpen ? "open" : ""}`}
                  />
                </div>
                {isOverviewOpen && (
                  <div className="submenu">
                    <Link
                      to="/FormCrearAsociados"
                      className={`submenu-item ${!company ? "disabled" : ""}`}
                    >
                      Crear
                    </Link>
                    {/* <Link
                      to="/dashboard"
                      className={`submenu-item ${!company ? "disabled" : ""}`}
                    >
                      Actualizar
                    </Link> */}
                    <Link
                      to="/FormUpdateAsociados"
                      className={`submenu-item ${!company ? "disabled" : ""}`}
                    >
                      Actualizar
                    </Link>
                  </div>
                )}
              </div>
            )}

            <div
              className={`menu-item ${!company ? "disabled" : ""}`}
              onClick={toggleProfile}
            >
              <HandCoins className="item-icon" />
              <span className="item-text">Procesos Financieros</span>
              <ChevronDown
                className={`chevron ${isProfileOpen ? "open" : ""}`}
              />
            </div>

            {isProfileOpen && (
              <div className="submenu">
                <div
                  className={`submenu-item ${!company ? "disabled" : ""}`}
                  onClick={() =>
                    company && setViewProfileOpen(!isViewProfileOpen)
                  }
                >
                  <span>Órdenes</span>
                  <ChevronDown
                    className={`chevron ${isViewProfileOpen ? "open" : ""}`}
                  />
                </div>
                {isViewProfileOpen && (
                  <div className="submenu">
                    <Link
                      to="/FormOrdenCompra"
                      className={`submenu-item ${!company ? "disabled" : ""}`}
                    >
                      Generar
                    </Link>
                    <Link
                      to="/ConsultarOrdenCompra"
                      className={`submenu-item ${!company ? "disabled" : ""}`}
                    >
                      Consultar
                    </Link>
                  </div>
                )}
                <div
                  className={`submenu-item ${!company ? "disabled" : ""}`}
                  onClick={toggleFacturas}
                >
                  <span>Facturas</span>
                  <ChevronDown
                    className={`chevron ${isFacturasOpen ? "open" : ""}`}
                  />
                </div>
                {isFacturasOpen && (
                  <div className="submenu">
                    <Link
                      to="/FormFacturasGeneral"
                      className={`submenu-item ${!company ? "disabled" : ""}`}
                    >
                      Consulta General
                    </Link>{" "}
                    <Link
                      to="/FormFacturasInterna"
                      className={`submenu-item ${!company ? "disabled" : ""}`}
                    >
                      Consulta Interna
                    </Link>
                    <Link
                      to="/FormFacturasCausar"
                      className={`submenu-item ${!company ? "disabled" : ""}`}
                    >
                      Causar
                    </Link>
                  </div>
                )}
              </div>
            )}

            {/* Icono de notificaciones */}
            <div className="menu-item notification-icon">
              <Link to="/HomeWelcomeBoard" className="home-link">
                <Badge
                  color="primary"
                  variant={hasUnreadNotifications ? "dot" : "standard"}
                >
                  <Bell className="item-icon" />
                </Badge>
                {isExpanded && (
                  <span className="item-text">Notificaciones</span>
                )}
              </Link>
            </div>

            <div className="logout-container" onClick={handleLogout}>
              <LogOut className="item-icon" />
              <span className="item-text">Cerrar Sesión</span>
            </div>
          </div>
        </div>
      </div>
      <div className="main-content">
        <Outlet />
      </div>
    </div>
  );
};

export default MenuBar;
