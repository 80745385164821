import React, { useState, useRef, useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import Dates1 from "./../components/FacturasForms/FacturasInternas/Dates1FacturasInt.js";
import "../styles/pages/OrdenCompra.pages.css";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useGlobalContext } from "../context/GlobalContext.js";
import { Link } from "react-router-dom";
import TableFacturaInt from "../components/FacturasForms/FacturasInternas/TableFacturaInt.js"; // Importar la tabla

const FormFacturasInterna = () => {
  const { user, company } = useGlobalContext();
  const [filters, setFilters] = useState({
    fechaordenDesde: null,
    fechaordenHasta: null,
    estadOrden: "Pendiente",
  });

  const [filteredDocuments, setFilteredDocuments] = useState([]); // Estado para documentos filtrados
  const mappedDocumentosRef = useRef([]); // Ref para almacenar mappedDocumentos sin filtro
  const handleFormDataChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const formDateBasicRef = useRef(null);
  const handleDocumentFiltering = (documents, mappedDocumentos) => {
    console.log(
      "Documentos filtrados se pasaron a FormFacturasGeneral:",
      documents,
    );
    console.log(
      "Todos los Documentos sin Filtros pasaron defecto a  FormFacturasGeneral:",
      mappedDocumentos,
    );

    // Actualizar `mappedDocumentosRef`
    mappedDocumentosRef.current = mappedDocumentos;

    // Actualizar documentos filtrados
    setFilteredDocuments(documents);
  };

  const Dates1Component = useMemo(
    () => (
      <Dates1
        handleFormDataChange={handleFormDataChange}
        onBuscar={(newFilters) =>
          setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }))
        }
        onFilterDocuments={handleDocumentFiltering}
      />
    ),
    [],
  );

  const customColumns = [
    { dataKey: "numfactura", label: "#FACTURA", width: "auto" },
    { dataKey: "fecha", label: "FECHA", width: "auto" },
    { dataKey: "valor", label: "VALOR", width: "auto" },
    { dataKey: "estadoFactura", label: "ESTADO", width: "auto" },
    { dataKey: "documento", label: "PDF", width: "auto" },
  ];

  const TableFacturaComponent = useMemo(
    () => (
      <TableFacturaInt
        filters={filters}
        documents={filteredDocuments}
        mappedDocumentos={mappedDocumentosRef.current}
        columns={customColumns} // Pasar las columnas seleccionadas
      />
    ),
    [filters, filteredDocuments, customColumns],
  );

  return (
    <div className="creation-form-container">
      <div className="form-header-ordencompra">
        <p>
          <strong>{company?.name || "Empresa no definida"}</strong>
        </p>
        <h1 className="form-title-factinternas">Facturas internas</h1>
        <Link to="/HomeWelcomeBoard">
          <IconButton aria-label="back" className="back-button">
            <KeyboardReturnIcon />
          </IconButton>
        </Link>
      </div>
      <form className="form-content">
        <div ref={formDateBasicRef} className="form-date-basic---orden">
          {Dates1Component}
          {TableFacturaComponent} {/* Memorizar este componente */}
          <div className="factura-section">
            <p>
              En este Panel podrá consultar las Facturas Internas que los
              usuarios de Contabilidad han redireccionado a cada Centro de
              Costos y este está asociado a cada Jefe de Proceso
              <strong>{user}</strong>, donde podrá Aprobar o Rechazar las
              Facturas, para que Contabilidad pueda gestionar su causación.
              Inicialmente al comienzo aparecerán las Facturas en las que su
              estado se encuentren en (Pendiente).
            </p>
            <br /> Pasos:
            <ol>
              <li>
                Si visualiza Facturas en estado Pendiente, por favor haga clic
                en el ícono PDF para revisar el documento y gestionar el proceso
                (Aprobar o Rechazar).
              </li>
              <li>
                Ingrese un rango de fechas del que desea consultar las Facturas
                redireccionadas.
              </li>
              <li>
                En Estado, seleccione el estado de las Facturas que desea
                visualizar (por defecto, Pendiente).
              </li>
              <li>
                Haga clic en Buscar para renderizar las Facturas en la tabla.
              </li>
            </ol>
            Además, tendrá un indicador visual (alerta) para detectar el tiempo
            en que se gestiona la factura:
            <ul>
              <li>Verde: Inicio del tiempo para gestionar.</li>
              <li>Amarillo: Tardanza en su gestión.</li>
              <li>Rojo: Próximo vencimiento para gestionar.</li>
            </ul>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormFacturasInterna;
