import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AlertComponent from "../hooks/Alerts"; // Asegúrate de que la ruta sea correcta
import { useAuth } from "../hooks/useAuth";
import { motion } from "framer-motion";
import Loading from "../hooks/loading.js"; // Importa el componente Loading
import "../styles/pages/Login.css";
import { Alert } from "@mui/material";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const navigate = useNavigate();
  const auth = useAuth();

  // Handle mobile view state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 390);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Animation timer
  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationComplete(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  // Clear error after a delay
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  const handleAlertClose = () => {
    setAlertVisible(false);
    setAlertType(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    // Flag to track if "sessionActive" alert is triggered
    let sessionActiveAlertShown = false;

    // Attempt login
    const success = await auth.login(username, password, (showAlert) => {
      if (showAlert) {
        // Show the "sessionActive" alert and prevent any other alert
        setAlertVisible(true);
        setAlertType("sessionActive");
        sessionActiveAlertShown = true; // Set flag to true
      }
    });

    setLoading(false);

    // Only show the error if the "sessionActive" alert is NOT visible
    if (!sessionActiveAlertShown && !success) {
      setError("Valide el Usuario y/o Contraseña!");
    }

    // Navigate if login is successful and no session active alert is shown
    if (!sessionActiveAlertShown && success) {
      navigate("/");
    }
  };

  const slideIn = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const scaleAndRotateAnimation = {
    hidden: { scale: 1.7, rotate: 0 },
    visible: {
      scale: 1,
      rotate: 0,
      transition: {
        duration: 2,
        delay: 0.6,
      },
    },
  };

  const welcomeSectionAnimation = {
    hidden: {
      width: "100%",
      borderBottomLeftRadius: "55px",
      borderTopLeftRadius: "55px",
    },
    visible: {
      width: "45%",
      borderBottomLeftRadius: "90px",
      borderTopLeftRadius: "90px",
      transition: {
        duration: 1,
        borderBottomLeftRadius: { duration: 1 },
        borderTopLeftRadius: { duration: 0.5, delay: 0.5 },
      },
    },
  };

  return (
    <div className="login-root">
      {alertVisible && (
        <AlertComponent type={alertType} onClose={handleAlertClose} />
      )}
      <div className="login-container">
        {loading && <Loading />}
        <motion.div
          className="login-form"
          variants={slideIn}
          initial="hidden"
          animate={animationComplete ? "visible" : "hidden"}
        >
          <motion.h2 variants={slideIn} initial="hidden" animate="visible">
            ¡Bienvenido!
          </motion.h2>
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <motion.input
                type="text"
                placeholder="Usuario"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                variants={slideIn}
                initial="hidden"
                animate="visible"
                autoComplete="current-password"
              />
            </div>
            <div className="input-group">
              <motion.input
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                variants={slideIn}
                initial="hidden"
                animate="visible"
                autoComplete="current-password"
              />
            </div>
            <motion.button
              type="submit"
              className="login-button"
              variants={slideIn}
              initial="hidden"
              animate="visible"
            >
              Iniciar sesión
            </motion.button>
          </form>
          <p className="firma">
            La solución para administrar sus procesos <br />
            de comercio exterior en tiempo real
          </p>

          {error && (
            <Alert className="error-alert" severity="error">
              {error}
            </Alert>
          )}
        </motion.div>
        <motion.div
          className={`welcome-section ${isMobile ? "hide-welcome" : ""}`}
          variants={welcomeSectionAnimation}
          initial="hidden"
          animate={animationComplete ? "visible" : "hidden"}
          onAnimationComplete={() => {
            if (isMobile) {
              setTimeout(() => {
                document.querySelector(".welcome-section").style.visibility =
                  "hidden";
              }, 2000);
            }
          }}
        >
          <h1></h1>
          <motion.div
            className="image-container"
            variants={scaleAndRotateAnimation}
            initial="hidden"
            animate="visible"
          ></motion.div>
        </motion.div>
      </div>
      <div className="bottom-image"></div>
    </div>
  );
}
