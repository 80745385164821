import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Autocomplete from "@mui/material/Autocomplete";
import TableAdjuntos from "./EditTableAdjuntos.js";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowDownIcon from "../../../../hooks/ArrowFlotant.js";
import ReactVirtualizedTable from "../../../ReactVirtualizedTable.js";
import {
  fetchFormasPago,
  fetchClasesProveedor,
  fetchBancos,
  fetchTiposProveedor,
  fetchCentrosCostos,
  fetchInformacionBancariaById,
} from "../../../../services/apiService.js";

const Proveedor = ({
  handleFormDataChange,
  parentRef,
  fetchedProveedorData,
}) => {
  const [isAutoretenedor, setIsAutoretenedor] = useState(false);
  const [isProveedorCertificado, setisProveedorCertificado] = useState(false);
  const [showArrowIcon, setShowArrowIcon] = useState(false);
  // Dentro de Proveedor, justo antes de utilizar TableAdjuntos:
  const adjuntosFromData =
    fetchedProveedorData && fetchedProveedorData.length > 0
      ? fetchedProveedorData[0].asociado_negocio.adjuntos
      : [];

  // Luego, al obtener los valores de TableAdjuntos:
  const { customRenderers, columns, data, uploadedFiles } = TableAdjuntos({
    fetchedAdjuntos: adjuntosFromData,
  });
  const [forma_pago, setMetodoPago] = useState(""); // Valor inicial válido

  const [claseProveedor, setClaseProveedor] = useState("");
  const [tipoProveedor, setTipoProveedor] = useState("");

  const [formasPagoOptions, setFormasPagoOptions] = useState([]);
  const [clasesProveedorOptions, setClasesProveedorOptions] = useState([]);
  const [bancosOptions, setBancosOptions] = useState([]);
  const [tiposProveedorOptions, setTiposProveedorOptions] = useState([]);
  const [centrosCostosOptions, setCentrosCostosOptions] = useState([]); // Lista de opciones
  const [selectedCentro, setSelectedCentro] = useState(null); // Centro seleccionad
  const [isAutoretenedorICA, setIsAutoretenedorICA] = useState(false);
  const [isResponsableIVA, setIsResponsableIVA] = useState(false);

  // === NUEVO EFECTO: MAPEO DE DATOS DEL JSON ===
  useEffect(() => {
    if (fetchedProveedorData && fetchedProveedorData.length > 0) {
      console.log("JSON recibido en PROVEEDOR:", fetchedProveedorData);
      const data = fetchedProveedorData[0].asociado_negocio;

      // Mapea clase_proveedor y actualiza el select correspondiente, añadiendo "00" al inicio
      const claseProveedorValue = data.clase_proveedor
        ? data.clase_proveedor.startsWith("00")
          ? data.clase_proveedor
          : "00" + data.clase_proveedor
        : "";
      setClaseProveedor(claseProveedorValue);
      handleFormDataChange("claseProveedor", claseProveedorValue);

      // Mapea tipo_proveedor y actualiza el select correspondiente
      setTipoProveedor(data.tipo_proveedor || "");
      handleFormDataChange("tipoProveedor", data.tipo_proveedor || "");

      // Mapea forma_pago para el método de pago
      setMetodoPago(data.forma_pago || "");
      handleFormDataChange("forma_pago", data.forma_pago || "");

      // Mapea responsabilidades (normalizamos a booleano)
      if (data.responsabilidades) {
        const responsableIVA = !!data.responsabilidades.responsable_iva;
        const autoretenedorICAValue =
          !!data.responsabilidades.autoretenedor_renta;
        const autoretenedorValue =
          !!data.responsabilidades.autoretenedor_reteica;

        setIsResponsableIVA(responsableIVA);
        handleFormDataChange("isResponsableIVA", responsableIVA);

        setIsAutoretenedorICA(autoretenedorICAValue);
        handleFormDataChange("isAutoretenedorICA", autoretenedorICAValue);

        setIsAutoretenedor(autoretenedorValue);
        handleFormDataChange("isAutoretenedor", autoretenedorValue);
      }

      // Mapea proveedor_calificado y actualiza el checkbox correspondiente
      const proveedorCalificado = !!data.proveedor_calificado;
      setisProveedorCertificado(proveedorCalificado);
      handleFormDataChange("isProveedorCertificado", proveedorCalificado);
    }
  }, [fetchedProveedorData, handleFormDataChange]);

  useEffect(() => {
    if (
      fetchedProveedorData &&
      fetchedProveedorData.length > 0 &&
      centrosCostosOptions &&
      centrosCostosOptions.length > 0
    ) {
      const data = fetchedProveedorData[0].asociado_negocio;
      const centro = centrosCostosOptions.find(
        (centro) => centro.value === data.id_centro_costos,
      );
      setSelectedCentro(centro || null);
      handleFormDataChange("centroCostos", data.id_centro_costos || "");
    }
  }, [fetchedProveedorData, centrosCostosOptions, handleFormDataChange]);

  useEffect(() => {
    const obtenerInformacionBancaria = async () => {
      if (fetchedProveedorData && fetchedProveedorData.length > 0) {
        const informacionBancariaId =
          fetchedProveedorData[0].informacion_bancaria_id;
        if (informacionBancariaId) {
          try {
            const respuesta = await fetchInformacionBancariaById(
              informacionBancariaId,
            );
            console.log(
              "Respuesta de la API (información bancaria):",
              respuesta,
            );
            if (
              respuesta &&
              respuesta.detalle_metodo_pago &&
              respuesta.detalle_metodo_pago.length > 0
            ) {
              const nuevosDetalles = respuesta.detalle_metodo_pago.map(
                (detalle, index) => ({
                  id: Date.now() + index, // Asegura un id único para cada elemento
                  nombre_banco: detalle.nombre_banco || "",
                  tipo_cuenta: detalle.tipo_cuenta || "",
                  numero_cuenta: detalle.numero_cuenta || "",
                  titular_cuenta: detalle.titular_cuenta || "",
                }),
              );
              setCuentasBancarias(nuevosDetalles);
            }
          } catch (error) {
            console.error("Error al obtener información bancaria:", error);
          }
        }
      }
    };
    obtenerInformacionBancaria();
  }, [fetchedProveedorData]);

  // Obtener los datos al cargar el componente
  useEffect(() => {
    const fetchData = async () => {
      const options = await fetchCentrosCostos();
      setCentrosCostosOptions(options);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setFormasPagoOptions(await fetchFormasPago());
      setClasesProveedorOptions(await fetchClasesProveedor());
      setBancosOptions(await fetchBancos());
      setTiposProveedorOptions(await fetchTiposProveedor());
    };
    fetchData();
  }, []);
  // Sincronizar archivos adjuntos con el estado general del formulario
  useEffect(() => {
    handleFormDataChange("adjuntos", uploadedFiles);
  }, [uploadedFiles, handleFormDataChange]);

  const scrollToTop = () => {
    if (parentRef && parentRef.current) {
      parentRef.current.scrollTo({
        top: -1,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    const fetchOptions = async () => {
      const fetchedTiposProveedor = await fetchTiposProveedor();
      // Si el valor actual de tipoProveedor no se encuentra en las opciones, se agrega
      if (
        tipoProveedor &&
        !fetchedTiposProveedor.find((option) => option.value === tipoProveedor)
      ) {
        fetchedTiposProveedor.push({
          value: tipoProveedor,
          label: tipoProveedor,
        });
      }
      setTiposProveedorOptions(fetchedTiposProveedor);
    };
    fetchOptions();
  }, [tipoProveedor]);

  useEffect(() => {
    const handleScroll = () => {
      if (parentRef && parentRef.current) {
        const isAtBottom =
          parentRef.current.scrollHeight - parentRef.current.scrollTop ===
          parentRef.current.clientHeight;
        setShowArrowIcon(isAtBottom);
      }
    };

    if (parentRef && parentRef.current) {
      parentRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (parentRef && parentRef.current) {
        parentRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [parentRef]);
  const validTipoProveedor = tiposProveedorOptions.find(
    (option) => option.value === tipoProveedor,
  )
    ? tipoProveedor
    : "";

  const validClaseProveedor = clasesProveedorOptions.find(
    (option) => option.value === claseProveedor,
  )
    ? claseProveedor
    : "";
  const validMetodoPago = formasPagoOptions.find(
    (option) => option.value === forma_pago,
  )
    ? forma_pago
    : "";
  // Estado para la sección dinámica de cuentas bancarias
  const [detalle_metodo_pago, setCuentasBancarias] = useState([
    {
      id: Date.now(),
      nombre_banco: "",
      tipo_cuenta: "",
      numero_cuenta: "",
      titular_cuenta: "",
    },
  ]);

  // Función para agregar una nueva fila de cuentas bancarias
  const addNewRow = () => {
    const newRow = {
      id: Date.now(),
      nombre_banco: "",
      tipo_cuenta: "",
      numero_cuenta: "",
      titular_cuenta: "",
    };
    setCuentasBancarias((prev) => [...prev, newRow]);
  };

  // Función para eliminar una fila de cuentas bancarias
  const removeRow = (id) => {
    setCuentasBancarias((prev) => prev.filter((row) => row.id !== id));
  };

  // Función para actualizar un campo en una fila de cuentas bancarias
  const updateCuentaField = (id, field, value) => {
    setCuentasBancarias((prev) => {
      const newArray = prev.map((row) =>
        row.id === id ? { ...row, [field]: value } : row,
      );
      // Actualiza el formData global con el array actualizado
      handleFormDataChange("detalle_metodo_pago", newArray);
      return newArray;
    });
  };

  // Sincronizar el array completo de cuentas bancarias con el formData global
  useEffect(() => {
    handleFormDataChange("detalle_metodo_pago", detalle_metodo_pago);
  }, [detalle_metodo_pago, handleFormDataChange]);
  // Función para renderizar la sección dinámica de cuentas bancarias
  const renderCuentasBancarias = () => {
    return (
      <div className="cuentas-bancarias-section">
        {detalle_metodo_pago.map((row) => (
          <div
            key={row.id}
            className={
              detalle_metodo_pago.length > 1
                ? "five-column-grid-modifi"
                : "three-column-grid"
            }
          >
            <FormControl className="input-form-control" required>
              <InputLabel id={`banco-label-${row.id}`} className="input-label">
                Banco
              </InputLabel>
              <Select
                labelId={`banco-label-${row.id}`}
                id={`banco-select-${row.id}`}
                value={
                  bancosOptions.some(
                    (option) => option.value === row.nombre_banco,
                  )
                    ? row.nombre_banco
                    : ""
                }
                onChange={(e) =>
                  updateCuentaField(row.id, "nombre_banco", e.target.value)
                }
                className="input-select"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {bancosOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="input-form-control" required>
              <InputLabel
                id={`tipo-cuenta-label-${row.id}`}
                className="input-label"
              >
                Tipo de cuenta
              </InputLabel>
              <Select
                labelId={`tipo-cuenta-label-${row.id}`}
                id={`tipo-cuenta-select-${row.id}`}
                value={row.tipo_cuenta}
                onChange={(e) =>
                  updateCuentaField(row.id, "tipo_cuenta", e.target.value)
                }
                className="input-select"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Corriente">Corriente</MenuItem>
                <MenuItem value="Ahorros">Ahorros</MenuItem>
                <MenuItem value="Exterior">Exterior</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id={`numero-cuenta-${row.id}`}
              label="Número de cuenta"
              variant="outlined"
              className="input-textfield"
              required
              value={row.numero_cuenta}
              onChange={(e) =>
                updateCuentaField(row.id, "numero_cuenta", e.target.value)
              }
            />
            <TextField
              id={`titular-cuenta-${row.id}`}
              label="Titular de la cuenta"
              variant="outlined"
              className="input-textfield item-3"
              required
              value={row.titular_cuenta}
              onChange={(e) =>
                updateCuentaField(row.id, "titular_cuenta", e.target.value)
              }
            />
            {detalle_metodo_pago.length > 1 && (
              <IconButton
                color="error"
                onClick={() => removeRow(row.id)}
                title="Eliminar fila"
              >
                <RemoveCircleIcon />
              </IconButton>
            )}
          </div>
        ))}
        <div style={{ marginTop: "1rem" }}>
          <Button
            variant="contained"
            onClick={addNewRow}
            sx={{ textTransform: "none" }}
            className="save-button mq2"
          >
            Agregar Bancos <AddCircleOutlineIcon />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="proveedor-container">
      {showArrowIcon && (
        <div className="icon-arrowdown-aso" onClick={scrollToTop}>
          <ArrowDownIcon />
        </div>
      )}
      <div className="proveedor-section">
        <h1 className="sucursal-title">Proveedor</h1>
        <div className="person-type">
          {/* Checkbox Proveedor Calificado */}
          <label className="checkbox-label">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={isProveedorCertificado} // Ya lo tienes
              onChange={(e) => {
                setisProveedorCertificado(e.target.checked);
                handleFormDataChange(
                  "isProveedorCertificado",
                  e.target.checked,
                );
              }}
            />
            <span className="checkbox-text">Proveedor Calificado</span>
          </label>

          {/* Checkbox Autoretenedor */}
          <label className="checkbox-label">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={isAutoretenedor} // Estado local que ya tienes
              onChange={(e) => {
                setIsAutoretenedor(e.target.checked);
                handleFormDataChange("isAutoretenedor", e.target.checked);
              }}
            />
            <span className="checkbox-text">Autoretenedor</span>
          </label>

          {/* Checkbox Autoretenedor ICA */}
          <label className="checkbox-label">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={isAutoretenedorICA} // Ahora controlado
              onChange={(e) => {
                setIsAutoretenedorICA(e.target.checked);
                handleFormDataChange("isAutoretenedorICA", e.target.checked);
              }}
            />
            <span className="checkbox-text">Autoretenedor ICA</span>
          </label>

          {/* Checkbox Responsable IVA */}
          <label className="checkbox-label">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={isResponsableIVA} // Ahora controlado
              onChange={(e) => {
                setIsResponsableIVA(e.target.checked);
                handleFormDataChange("isResponsableIVA", e.target.checked);
              }}
            />
            <span className="checkbox-text">Responsable IVA</span>
          </label>
        </div>

        <div className="three-column-grid">
          {/* Método de Pago */}
          <FormControl className="input-form-control" required>
            <InputLabel id="metodo-pago-label" className="input-label">
              Método de pago
            </InputLabel>
            <Select
              labelId="metodo-pago-label"
              id="metodo-pago-select"
              value={validMetodoPago}
              onChange={(e) => {
                setMetodoPago(e.target.value);
                handleFormDataChange("forma_pago", e.target.value);
              }}
              className="input-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {formasPagoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className="input-form-control" required>
            <InputLabel id="clase-proveedor-label" className="input-label">
              Clase de proveedor
            </InputLabel>
            <Select
              labelId="clase-proveedor-label"
              id="clase-proveedor-select"
              value={validClaseProveedor}
              onChange={(e) => {
                setClaseProveedor(e.target.value); // Actualiza el estado local con el value
                handleFormDataChange("claseProveedor", e.target.value); // Envía el value al formData
              }}
              className="input-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {clasesProveedorOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Campo para seleccionar el Centro de Costos */}
          <Autocomplete
            className="input-form-control"
            disablePortal
            options={centrosCostosOptions}
            getOptionLabel={(option) => option.label}
            value={selectedCentro}
            onChange={(event, value) => {
              setSelectedCentro(value || null);
              handleFormDataChange("centroCostos", value?.value || null);
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Centro de Costos"
                className="input-select"
                required
                InputLabelProps={{
                  className: "input-label",
                }}
                InputProps={{
                  ...params.InputProps,
                  className: "input-form-control",
                }}
              />
            )}
          />
        </div>

        {/* Sección dinámica para Cuentas Bancarias */}
        {renderCuentasBancarias()}

        <div className="three-column-grid">
          <FormControl className="input-form-control item-1" required>
            <InputLabel id="tipo-proveedor-label" className="input-label">
              Tipo de proveedor
            </InputLabel>
            <Select
              labelId="tipo-proveedor-label"
              id="tipo-proveedor-select"
              value={validTipoProveedor}
              onChange={(e) => {
                setTipoProveedor(e.target.value); // Actualiza el estado local con el value
                handleFormDataChange("tipoProveedor", e.target.value); // Envía el value al formData
              }}
              className="input-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {tiposProveedorOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {isAutoretenedor && (
            <TextField
              id="numero-resolucion"
              label="Número de resolución"
              variant="outlined"
              className="input-textfield item-2"
              required
              onChange={(e) =>
                handleFormDataChange("numeroResolucion", e.target.value)
              }
            />
          )}
        </div>
      </div>

      <ReactVirtualizedTable
        columns={columns}
        data={data}
        customRenderers={customRenderers}
      />
    </div>
  );
};

export default Proveedor;
