import React, { useState, useEffect } from "react";
import { CustomSelect, CustomDateField } from "../Forms/CustomAutocomplete";
import { Button } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { fetchEstadosOrdenCompra } from "../../services/OrdenCompraService";
import { fetchOrdenesCompra } from "../../services/apiService";
import { useGlobalContext } from "../../context/GlobalContext";

const Dates3 = ({ handleFormDataChange, onBuscar }) => {
  const { userInfo } = useGlobalContext();
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [formData, setFormData] = useState({
    fechaordenDesde: null,
    fechaordenHasta: null,
    estadOrden: "Revisión", // Valor por defecto
  });

  const [tipoDocumentoOptions, setTipoDocumentoOptions] = useState([]);
  const [isOptionsLoaded, setIsOptionsLoaded] = useState(false);

  // Cargar opciones de estado solo una vez
  useEffect(() => {
    if (!isOptionsLoaded) {
      const obtenerEstados = async () => {
        try {
          const estados = await fetchEstadosOrdenCompra();
          const opciones = estados.map((estado) => ({
            value: estado.nombre,
            label: estado.nombre,
            id: estado.estado_id, // Almacenar estado_id
          }));
          setTipoDocumentoOptions(opciones);
          console.log("Listado de estadOrden:", opciones);

          // Si el estado predeterminado no está en las opciones, ajustarlo
          if (
            !opciones.find((opcion) => opcion.value === formData.estadOrden)
          ) {
            setFormData((prev) => ({
              ...prev,
              estadOrden: opciones[0]?.value || "",
            }));
          }
          setIsOptionsLoaded(true);
        } catch (error) {
          console.error(
            "Error al obtener los estados de orden de compra:",
            error,
          );
        }
      };

      obtenerEstados();
    }
  }, [isOptionsLoaded, formData.estadOrden]);

  // Actualizar el reloj cada segundo
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const cargarOrdenesInicial = async () => {
      if (!userInfo?.centros_costos) return;

      const centrosCostos = userInfo.centros_costos.map(
        (centro) => centro.centro_costos_id,
      );
      try {
        const data = await fetchOrdenesCompra(centrosCostos, "", "");
        const filteredData = data.filter(
          (item) => item.estado.toLowerCase() === "revisión",
        );
        console.log("Listado de órdenes iniciales:", filteredData); // Agregar esta línea
        onBuscar({ data: filteredData, isInitialLoad: true });
      } catch (error) {
        console.error("Error en carga inicial:", error);
      }
    };

    cargarOrdenesInicial();
  }, [userInfo, onBuscar]);

  const handleBuscar = async () => {
    if (!userInfo?.centros_costos) return;

    const formattedFormData = {
      ...formData,
      fechaordenDesde: formData.fechaordenDesde
        ? new Date(formData.fechaordenDesde).toISOString().slice(0, 10)
        : null,
      fechaordenHasta: formData.fechaordenHasta
        ? new Date(formData.fechaordenHasta).toISOString().slice(0, 10)
        : null,
    };

    // Validar que las fechas sean consistentes
    if (
      formattedFormData.fechaordenDesde &&
      formattedFormData.fechaordenHasta &&
      new Date(formattedFormData.fechaordenDesde) >
        new Date(formattedFormData.fechaordenHasta)
    ) {
      alert(
        "La fecha 'desde' no puede ser posterior a la fecha 'hasta'. Ajusta el rango de fechas.",
      );
      return;
    }

    try {
      const centrosCostos = userInfo.centros_costos.map(
        (centro) => centro.centro_costos_id,
      );
      const estadoSeleccionado = tipoDocumentoOptions.find(
        (option) => option.value === formData.estadOrden,
      );
      const estadoId = estadoSeleccionado ? estadoSeleccionado.id : "";

      const data = await fetchOrdenesCompra(
        centrosCostos,
        formattedFormData.fechaordenDesde || "",
        formattedFormData.fechaordenHasta || "",
        estadoId,
      );

      const filteredData = data.filter(
        (item) =>
          item.estado.toLowerCase() === formData.estadOrden.toLowerCase(),
      );
      console.log("Listado de órdenes buscadas:", filteredData); // Agregar esta línea
      onBuscar({ data: filteredData, isInitialLoad: false });
    } catch (error) {
      console.error("Error al buscar órdenes:", error);
    }
  };

  // Actualizar el estado del formulario y llamar al manejador
  const handleFieldChange = (field, value) => {
    const updatedFormData = { ...formData, [field]: value };
    setFormData(updatedFormData);
    handleFormDataChange(field, value);
  };

  const formatDate = (date) => {
    return date
      ? date.toLocaleDateString("es-CO", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      : "";
  };

  return (
    <div className="orden-date1-section">
      <div className="two-column-grid">
        <p className="p-align-izq">
          <strong>Fecha: </strong> {formatDate(currentDateTime)}
        </p>
        <div className="generate-button-container-factint">
          <Button
            variant="contained"
            className="save-button"
            onClick={handleBuscar}
            sx={{ textTransform: "none" }}
          >
            <SaveAltIcon />
            Buscar
          </Button>
        </div>
      </div>
      <div className="three-column-grid">
        <CustomDateField
          id="fecha-desde"
          label="Fecha Orden Generada (Desde)"
          value={formData.fechaordenDesde}
          onChange={(newValue) =>
            handleFieldChange("fechaordenDesde", newValue)
          }
        />
        <CustomDateField
          id="fecha-hasta"
          label="Fecha Orden Generada (Hasta)"
          value={formData.fechaordenHasta}
          onChange={(newValue) =>
            handleFieldChange("fechaordenHasta", newValue)
          }
        />
        {isOptionsLoaded && (
          <CustomSelect
            id="estado-orden"
            label="Estado"
            value={formData.estadOrden}
            options={tipoDocumentoOptions}
            onChange={(e) => handleFieldChange("estadOrden", e.target.value)}
          />
        )}
      </div>
    </div>
  );
};

export default Dates3;
