import React, { useState, useRef, useMemo, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Dates3 from "../OrdenCompraForms/Dates1DetailsOrden.js";
import "../../styles/pages/OrdenCompra.pages.css";
import { Check, X } from "lucide-react";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useGlobalContext } from "../../context/GlobalContext.js";
import {
  CustomTextField,
  CustomTextarea,
  CustomDateField,
} from "../Forms/CustomAutocomplete.js";
import TableFactura from "../OrdenCompraForms/TableFacturaAprvRechz.js";
import { Link, useParams } from "react-router-dom";
import { useAlert } from "../../hooks/useAlert"; // Hook personalizado
import Loading from "../../hooks/loading.js"; // Componente de Loading
import { fetchOrdenCompraById } from "../../services/apiService"; // Importa las APIs
import {
  approveOrdenCompra,
  rejectOrdenCompra,
} from "../../services/OrdenCompraService.js"; // Importa las APIs

const DetalleOrden = () => {
  const { user, area, company, userInfo } = useGlobalContext();
  const { triggerAlert, Alert } = useAlert();
  const { id: numeroOrden } = useParams(); // Obtener el número de orden desde la URL

  const [isLoading, setIsLoading] = useState(false); // Estado para controlar el loading
  const [isActionCompleted, setIsActionCompleted] = useState(false); // Estado para deshabilitar botones
  const [formData, setFormData] = useState({
    subtotal: 0,
    iva: 19,
    total: "",
    justificacion: "",
    observaciones: "",
    fechaAnteriordeCompra: "",
    estadoOrden: "", // Estado de la orden: "Aprobado" o "Rechazado"
    tableData: [],
    usuario: user,
    cargo: area,
  });

  useEffect(() => {
    const cargarDatosOrden = async () => {
      try {
        const data = await fetchOrdenCompraById(numeroOrden); // Llamada a la API
        if (data) {
          setFormData((prevData) => ({
            ...prevData,
            metodoPago: data.documento.metodoPago || "",
            subtotal: data.documento.subtotal || 0,
            estadoId: data.documento.info_gestion_estados?.estado_id || null,
            fechaAnteriordeCompra: data.documento.fecha_anterior_compra || null,
            fechaEntrega: data.documento.fecha_de_entrega || null,
            iva: data.documento.iva || 0,
            total: data.documento.total || 0,
            justificacion: data.documento.justificacion || "",
            observaciones: data.documento.observaciones || "",
            tableData: data.documento.items.map((item, index) => ({
              id: index + 1,
              cant: item.cantidad,
              u_medida: item.unidad_de_medida,
              description: item.descripcion,
              price_unit: item.valor_unitario,
              v_total: item.valor_total,
              disabled: true, // Hacer las filas no editables
            })),
            usuarioId: data.documento.creado_por
              ? `${data.documento.creado_por.nombre || "Nombre no disponible"} ${data.documento.creado_por.apellido || "Apellido no disponible"}`
              : "No especificado",
            estadosId:
              data.documento.info_gestion_estados?.descripcion ||
              "No especificado",
            aprobadoRechazadoPor: data.documento.gestionado_por
              ? `${data.documento.gestionado_por.nombre || "Nombre no disponible"} ${data.documento.gestionado_por.apellido || "Apellido no disponible"}`
              : "No especificado",
          }));
        }
      } catch (error) {
        console.error("Error al cargar la orden de compra:", error);
      }
    };

    cargarDatosOrden();
  }, [numeroOrden]);

  // Función para manejar la aprobación
  const handleApprove = async () => {
    try {
      setIsLoading(true);
      const response = await approveOrdenCompra(numeroOrden);
      console.log("Respuesta de aprobación:", response);
      triggerAlert("success", {});
      setFormData((prevData) => ({
        ...prevData,
        estadoOrden: "Aprobado",
        aprobadoRechazadoPor: user,
      }));
    } catch (error) {
      console.error("Error al aprobar la orden:", error);
    } finally {
      setIsLoading(false);
      setIsActionCompleted(true);
    }
  };

  // Función para manejar el rechazo
  const handleReject = async () => {
    try {
      setIsLoading(true);
      const response = await rejectOrdenCompra(numeroOrden);
      console.log("Respuesta de rechazo:", response);
      triggerAlert("error", { message: "Orden rechazada con éxito." });
      setFormData((prevData) => ({
        ...prevData,
        estadoOrden: "Rechazado",
        aprobadoRechazadoPor: user,
      }));
    } catch (error) {
      console.error("Error al rechazar la orden:", error);
      triggerAlert("error", { message: "Error al rechazar la orden." });
    } finally {
      setIsLoading(false);
      setIsActionCompleted(true);
    }
  };

  const handleFormDataChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const Dates3Component = useMemo(
    () => <Dates3 handleFormDataChange={handleFormDataChange} />,
    [],
  );

  // Verificar si el rol del usuario es 'Sublíder'
  const isSublider = userInfo?.centros_costos?.some(
    (centro) => centro.rol === "Sublíder",
  );

  return (
    <div className="creation-form-container">
      {isLoading && <Loading />} {/* Muestra el componente Loading */}
      <div className="form-header-ordencompra">
        <p>
          <strong>{company?.name}</strong>
        </p>
        <h1 className="form-title-ordencompra">
          Orden de compra y/o servicios
        </h1>
        <Link to="/ConsultarOrdenCompra">
          <IconButton aria-label="back" className="back-button">
            <KeyboardReturnIcon />
          </IconButton>
        </Link>
      </div>
      <form className="form-content">
        <div ref={useRef(null)} className="form-date-basic---orden">
          {Dates3Component}
          <TableFactura formData={formData} setFormData={setFormData} />
          <div className="factura-section">
            <div className="two-column-grid">
              <CustomTextField
                id="metodo-pago"
                label="Método de pago"
                value={formData.metodoPago || "No especificado"}
                disabled
              />
              <CustomTextField
                id="subtotal"
                label="SUBTOTAL"
                value={formData.subtotal}
                disabled
              />
            </div>
            <div className="two-column-grid">
              {formData.fechaAnteriordeCompra ? (
                <CustomDateField
                  id="fecha-sin-limite"
                  label="Fecha anterior de compra"
                  value={formData.fechaAnteriordeCompra}
                  disabled
                />
              ) : (
                <p>
                  <strong>Fecha Anterior de Compra:</strong> Esta es la primera
                  compra registrada
                </p>
              )}
              <CustomTextField
                id="iva"
                label="IVA (%)"
                value={formData.iva}
                disabled
              />
            </div>
            <div className="two-column-grid">
              <CustomDateField
                id="fecha-entrega"
                label="Fecha de entrega"
                value={formData.fechaEntrega}
                disabled
              />
              <CustomTextField
                id="total"
                label="TOTAL"
                value={formData.total}
                disabled
              />
            </div>
            <div className="two-column-grid">
              <CustomTextarea
                id="justificacion"
                value={formData.justificacion}
                placeholder="Escribe aquí la justificación..."
                minRows={5}
                disabled
              />
              <CustomTextarea
                id="observaciones"
                value={formData.observaciones}
                placeholder="Escribe aquí las observaciones..."
                minRows={5}
                disabled
              />
            </div>
            <div className="two-column-grid sp">
              <p>
                <strong>Solicitado por: </strong>
                {formData.usuarioId || "No especificado"} <br />
                <strong>Cargo:</strong> {area}
              </p>
              <p>
                <strong>{formData.estadosId || "No especificado"} por: </strong>
                {formData.aprobadoRechazadoPor || "No especificado"} <br />
                <strong>Cargo:</strong> {area}
              </p>
            </div>
          </div>
        </div>
        <div className="verorden-button-container-orden">
          {!isActionCompleted &&
            formData.estadoId !== 3 &&
            formData.estadoId !== 4 &&
            (isSublider ? (
              <button
                type="button"
                className="alert-button red"
                onClick={handleReject}
                disabled={isLoading}
              >
                <X className="icon" />
                Rechazar
              </button>
            ) : (
              <>
                <button
                  type="button"
                  className="alert-button red"
                  onClick={handleReject}
                  disabled={isLoading}
                >
                  <X className="icon" />
                  Rechazar
                </button>
                <button
                  type="button"
                  className="alert-button green"
                  onClick={handleApprove}
                  disabled={isLoading}
                >
                  <Check className="icon" />
                  Aprobar
                </button>
              </>
            ))}
        </div>

        <Alert />
      </form>
    </div>
  );
};

export default DetalleOrden;
