import React, { useState, useRef, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom"; // Importa Link de react-router-dom
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import PersonaJuridica from "../components/AsociadosForms/UpdateAsociados/EditPersonaJuridica.js";
import PersonaNatural from "../components/AsociadosForms/UpdateAsociados/EditPersonaNatural.js";
import Proveedor from "../components/AsociadosForms/UpdateAsociados/Proveedor/EditProveedor.js";
import FormVerify from "../hooks/FormVerify.js";
import "../styles/pages/Asociados.pages.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowDownIcon from "../hooks/ArrowFlotant.js";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import AsociadosVerify from "../components/AsociadosForms/AsociadosVerify.js";
import HomeWelcomeBoard from "./HomeWelcomeBoard.js"; // Importa tu componente
import { data as documentData } from "../components/AsociadosForms/UpdateAsociados/Proveedor/EditTableAdjuntos.js";
import { fetchPaises, fetchCiudadesPorPais } from "../services/apiService.js";
import "../styles/stylesOficial.css";
import {
  updateAsociadoDeNegocio,
  updateInformacionBancariaById,
  buscarProveedorPorNit,
} from "../services/apiService.js";
import { useGlobalContext } from "../context/GlobalContext.js";
import { useLocation } from "react-router-dom";
import { useAlert } from "../hooks/useAlert.js";

const UpdateAsociados = () => {
  const [showArrowIcon, setShowArrowIcon] = useState(true);
  const [showFormVerify, setShowFormVerify] = useState(false);
  const [paises, setPaises] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const { company } = useGlobalContext();

  const { triggerAlert, Alert } = useAlert();
  const [formData, setFormData] = useState({
    name: "",
    country: "",
    email: "",
    numero: "",
    correo: "",
    ciiu: "",
    forma_pago: "",
    centroCostos: "",
    tipoProveedor: "",
    claseProveedor: "",
    adjuntos: {}, // Convertido a objeto para mapeo directo por id
  });
  const location = useLocation();
  const { nit_proveedor } = location.state || {}; // Recupera el nit_proveedor
  const [fetchedProveedorData, setFetchedProveedorData] = useState(null);

  const proveedorRef = useRef(null);
  const formDateBasicRef = useRef(null);
  /*  useEffect(() => {
    console.log("===== FormData actualizado =====");
    console.table(formData); // Muestra los datos en formato tabla en la consola
  }, [formData]); */

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (name, checked) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleFormDataChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleAdjuntosChange = (uploadedFiles) => {
    setFormData((prevData) => ({
      ...prevData,
      adjuntos: uploadedFiles,
    }));
  };

  const handleNaturalChange = () => {
    setFormData((prevData) => ({
      ...prevData,
      isNatural: !prevData.isNatural,
      isJuridica: prevData.isNatural,
    }));
  };

  const handleJuridicaChange = () => {
    setFormData((prevData) => ({
      ...prevData,
      isNatural: prevData.isJuridica,
      isJuridica: !prevData.isJuridica,
    }));
  };
  // Función para manejar el cambio de país y cargar las ciudades
  // Función para manejar el cambio de país y cargar las ciudades
  const handleCountryChange = async (event, value) => {
    setFormData((prevData) => ({
      ...prevData,
      country: value?.value || "",
      city: "", // Reiniciar la ciudad cuando se selecciona un nuevo país
    }));

    if (value?.value) {
      try {
        const ciudadesData = await fetchCiudadesPorPais(value.value);
        /*   console.log(
          "Ciudades retornadas de fetchCiudadesPorPais para el país",
          value.value,
          ":",
          ciudadesData,
        ); */
        setCiudades(ciudadesData);
        return ciudadesData; // Retornamos el listado de ciudades
      } catch (error) {
        console.error("Error al cargar las ciudades:", error);
        setCiudades([]);
        return [];
      }
    } else {
      setCiudades([]);
      return [];
    }
  };

  useEffect(() => {
    const cargarDatosUnificado = async () => {
      // Caso 1: Si existen datos en fetchedProveedorData
      if (fetchedProveedorData && fetchedProveedorData.length > 0) {
        // Obtenemos el arreglo completo de centro_operaciones del JSON recibido
        const centrosOperaciones =
          fetchedProveedorData[0].asociado_negocio?.centro_operaciones;

        if (centrosOperaciones && centrosOperaciones.length > 0) {
          // Mapeamos cada centro para crear un arreglo de sucursales con id único
          const sucursalesArray = centrosOperaciones.map((centro, index) => {
            const ubicacion = centro.ubicacion
              ? centro.ubicacion.toString()
              : "";
            return {
              id: centro.id || index + 1, // Si el JSON no trae id, se genera uno
              name: centro.nombre_centro_operaciones || "",
              email:
                centro.email_facturacion_electronica_proveedor &&
                centro.email_facturacion_electronica_proveedor.length > 0
                  ? centro.email_facturacion_electronica_proveedor[0]
                  : "",
              city: ubicacion, // Inicialmente se asigna la ubicación como ciudad
              address: centro.direccion || "",
              phone1:
                centro.telefonos && centro.telefonos.length > 0
                  ? centro.telefonos[0]
                  : "",
              phone2:
                centro.telefonos && centro.telefonos.length > 1
                  ? centro.telefonos[1]
                  : "",
              country: centro.pais || "",
              showSecondPhone: false, // Valor inicial para el control de teléfono 2
            };
          });

          // Actualizamos la ciudad para cada sucursal que tenga un country definido.
          // Se hace de forma asíncrona para cada sucursal.
          const updatedSucursales = await Promise.all(
            sucursalesArray.map(async (sucursal) => {
              if (sucursal.country) {
                // Obtenemos las ciudades según el país de la sucursal
                const ciudadesData = await handleCountryChange(null, {
                  value: sucursal.country,
                });
                // Buscamos la coincidencia usando el zipcode (o el valor que tenga)
                const matchingCity = ciudadesData.find(
                  (ciudad) => ciudad.zipcode === sucursal.city,
                );
                const finalCity = matchingCity
                  ? matchingCity.value
                  : sucursal.city;
                return { ...sucursal, city: finalCity };
              }
              return sucursal;
            }),
          );

          // Actualizamos el estado de sucursales y formData
          setSucursales(updatedSucursales);
          setFormData((prev) => ({
            ...prev,
            centro_operaciones: updatedSucursales,
          }));
        }
      }

      // Caso 2: Si no hay fetchedProveedorData pero sí se tiene formData.numero
      else if (formData.numero) {
        try {
          const proveedor = await buscarProveedorPorNit(formData.numero);
          if (proveedor && proveedor.total !== 0) {
            // Normalizamos el país para que coincida con las opciones de "paises"
            const matchingCountry = paises.find(
              (pais) =>
                pais.label.toLowerCase() === proveedor.pais.toLowerCase(),
            );
            // Actualizamos la lista de ciudades si se encontró el país
            if (matchingCountry) {
              const ciudadesData = await fetchCiudadesPorPais(
                matchingCountry.value,
              );
              setCiudades(ciudadesData);
              // Buscamos la ciudad que coincida con el valor de proveedor.ciudad
              const matchingCity = ciudadesData.find(
                (ciudad) => ciudad.value === proveedor.ciudad,
              );
              setFormData((prevData) => ({
                ...prevData,
                name: proveedor.razonSocialjuridico || "",
                address: proveedor.direccion || "",
                email: proveedor.correo || "",
                phone1: proveedor.telefono || "",
                country: matchingCountry.value || "",
                city: matchingCity ? matchingCity.value : "",
              }));
            } else {
              // Si no se encuentra país coincidente, reiniciamos la lista de ciudades y los campos de país y ciudad
              setCiudades([]);
              setFormData((prevData) => ({
                ...prevData,
                country: "",
                city: "",
              }));
            }
          }
        } catch (error) {
          console.error("Error al cargar los datos del proveedor:", error);
        }
      }
    };

    cargarDatosUnificado();
  }, [fetchedProveedorData, formData.numero, paises]);

  const isSaveEnabled =
    formData.isEmpleado || formData.isCliente || formData.isProveedor;
  const scrollToProveedor = () => {
    if (proveedorRef.current) {
      proveedorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setShowArrowIcon(false);
    }
  };
  useEffect(() => {
    const cargarPaises = async () => {
      try {
        const data = await fetchPaises();
        setPaises(data.map((pais) => ({ label: pais, value: pais })));
      } catch (error) {
        console.error("Error al cargar los países:", error);
      }
    };
    cargarPaises();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setShowArrowIcon(formDateBasicRef.current.scrollTop === 0);
    };

    const formDateBasicElement = formDateBasicRef.current;
    formDateBasicElement.addEventListener("scroll", handleScroll);

    return () => {
      formDateBasicElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSave = (e) => {
    e.preventDefault();

    // Si formData.name o formData.email están vacíos, intentamos obtenerlos del JSON recibido
    const nameFromFetched =
      fetchedProveedorData &&
      fetchedProveedorData.length > 0 &&
      fetchedProveedorData[0].asociado_negocio?.centro_operaciones &&
      fetchedProveedorData[0].asociado_negocio.centro_operaciones.length > 0
        ? fetchedProveedorData[0].asociado_negocio.centro_operaciones[0]
            .nombre_centro_operaciones || ""
        : "";
    const emailFromFetched =
      fetchedProveedorData &&
      fetchedProveedorData.length > 0 &&
      fetchedProveedorData[0].asociado_negocio?.centro_operaciones &&
      fetchedProveedorData[0].asociado_negocio.centro_operaciones.length > 0
        ? fetchedProveedorData[0].asociado_negocio.centro_operaciones[0]
            .email_facturacion_electronica_proveedor?.[0] || ""
        : "";

    // Usamos los valores de formData si existen; si no, usamos los del JSON
    const nameValue = formData.name?.trim() || nameFromFetched.trim() || "";
    const emailValue = formData.email?.trim() || emailFromFetched.trim() || "";

    // Definimos los campos requeridos básicos del proveedor
    const requiredProviderFields = [
      "name",
      "tipoDocumento",
      "numero",
      "ciiu",
      ...(formData.isJuridica ? ["razonSocial"] : []),
      "correo",
      "country",
      "email",
    ];

    // Creamos un objeto local con los valores a usar en la validación para los campos básicos
    const basicValues = {
      name: nameValue,
      tipoDocumento: formData.tipoDocumento,
      numero: formData.numero,
      ciiu: formData.ciiu,
      razonSocial: formData.razonSocial,
      correo: formData.correo,
      country: formData.country,
      email: emailValue,
    };

    // Se valida la información básica del proveedor
    let missingFields = requiredProviderFields.filter(
      (field) =>
        !basicValues[field] ||
        (typeof basicValues[field] === "string" &&
          basicValues[field].trim() === ""),
    );

    // Validar la información de las sucursales (si existen)
    if (formData.centro_operaciones && formData.centro_operaciones.length > 0) {
      formData.centro_operaciones.forEach((sucursal, index) => {
        const requiredSucursalFields = [
          "name",
          "country",
          "city",
          "address",
          "phone1",
        ];
        requiredSucursalFields.forEach((field) => {
          if (
            !sucursal[field] ||
            (typeof sucursal[field] === "string" &&
              sucursal[field].trim() === "")
          ) {
            missingFields.push(`${field} (sucursal ${index + 1})`);
          }
        });
      });
    } else {
      // Si no existen sucursales, se asume que se usan los datos del proveedor para estos campos
      const extraProviderFields = ["city", "address", "phone1"];
      missingFields = missingFields.concat(
        extraProviderFields.filter(
          (field) =>
            !formData[field] ||
            (typeof formData[field] === "string" &&
              formData[field].trim() === ""),
        ),
      );
    }

    // Validar las cuentas bancarias en caso de que el proveedor lo requiera
    if (formData.isProveedor) {
      if (
        !formData.detalle_metodo_pago ||
        formData.detalle_metodo_pago.length === 0
      ) {
        missingFields.push("cuentas bancarias");
      } else {
        formData.detalle_metodo_pago.forEach((cuenta, index) => {
          if (
            !cuenta.nombre_banco ||
            cuenta.nombre_banco.toString().trim() === ""
          )
            missingFields.push(`banco (cuenta ${index + 1})`);
          if (
            !cuenta.tipo_cuenta ||
            cuenta.tipo_cuenta.toString().trim() === ""
          )
            missingFields.push(`tipo_cuenta (cuenta ${index + 1})`);
          if (
            !cuenta.numero_cuenta ||
            cuenta.numero_cuenta.toString().trim() === ""
          )
            missingFields.push(`numero_cuenta (cuenta ${index + 1})`);
          if (
            !cuenta.titular_cuenta ||
            cuenta.titular_cuenta.toString().trim() === ""
          )
            missingFields.push(`titular_cuenta (cuenta ${index + 1})`);
        });
      }
    }

    // Si se detectan campos faltantes, se muestra la alerta
    if (missingFields.length > 0) {
      alert(
        `Por favor completa todos los campos requeridos: ${missingFields.join(", ")}`,
      );
      return;
    }

    // Si todo está correcto, se procede a mostrar la verificación del formulario
    setShowFormVerify(true);
  };

  // Función para transformar el formData a la estructura requerida por el backend
  const transformFormDataToPayload = (formData) => {
    // Función auxiliar para fusionar los adjuntos
    const mergeAdjuntos = () => {
      // Obtenemos los adjuntos originales del JSON recibido
      const fetchedAdjuntos =
        fetchedProveedorData && fetchedProveedorData.length > 0
          ? fetchedProveedorData[0].asociado_negocio.adjuntos || []
          : [];

      // Si no se han subido nuevos adjuntos, retornamos los originales
      if (!formData.adjuntos || Object.keys(formData.adjuntos).length === 0) {
        return fetchedAdjuntos;
      }

      // Usamos un Set para obtener todos los índices que existan en el arreglo original y/o en formData.adjuntos
      const indicesSet = new Set();
      for (let i = 0; i < fetchedAdjuntos.length; i++) {
        indicesSet.add(i);
      }
      Object.keys(formData.adjuntos).forEach((key) => {
        const index = parseInt(key, 10);
        if (!isNaN(index)) {
          indicesSet.add(index);
        }
      });
      const indices = Array.from(indicesSet).sort((a, b) => a - b);

      // Para cada índice, si existe una entrada en formData.adjuntos y además tiene un archivo nuevo,
      // usamos ese; de lo contrario, si el índice existe en los adjuntos originales, se conserva ese.
      return indices.map((i) => {
        if (Object.prototype.hasOwnProperty.call(formData.adjuntos, i)) {
          // Si se subió un archivo nuevo en este índice, lo usamos
          if (formData.adjuntos[i].file) {
            return {
              ruta_archivo: formData.adjuntos[i].path || "",
              nombre_archivo: formData.adjuntos[i].name || "",
            };
          }
          // Si hay clave en formData.adjuntos pero no hay archivo nuevo, asumimos que no se actualizó:
          if (i < fetchedAdjuntos.length) {
            return fetchedAdjuntos[i];
          }
          // Si no hay archivo y tampoco en fetched, devolvemos un objeto vacío (o lo omitimos)
          return { ruta_archivo: "", nombre_archivo: "" };
        } else if (i < fetchedAdjuntos.length) {
          return fetchedAdjuntos[i];
        }
        // En caso extremo, devolvemos un objeto vacío
        return { ruta_archivo: "", nombre_archivo: "" };
      });
    };

    // Construimos el payload usando la función mergeAdjuntos para el campo "adjuntos"
    const payload = {
      asociado_de_negocio_id:
        formData.asociado_de_negocio_id ||
        (fetchedProveedorData && fetchedProveedorData.length > 0
          ? fetchedProveedorData[0].asociado_de_negocio_id
          : null),
      asociado_negocio: {
        adjuntos: mergeAdjuntos(),
        telefono: formData.phone1,
        forma_pago: formData.forma_pago,
        id_compania: formData.id_compania || 1,
        es_proveedor: formData.isProveedor,
        razon_social: formData.razonSocial || null,
        tipo_persona: formData.isNatural ? "NATURAL" : "JURÍDICA",
        primer_nombre: formData.primerNombre,
        segundo_nombre: formData.segundoNombre ? formData.segundoNombre : null,
        tipo_documento: formData.tipoDocumento,
        tipo_proveedor: formData.tipoProveedor,
        clase_proveedor: formData.claseProveedor,
        primer_apellido: formData.primerApellido,
        id_centro_costos: formData.centroCostos,
        segundo_apellido: formData.segundoApellido
          ? formData.segundoApellido
          : null,
        responsabilidades: {
          responsable_iva: formData.isResponsableIVA,
          autoretenedor_renta: formData.isAutoretenedor,
          autoretenedor_reteica: formData.isAutoretenedorICA,
        },
        centro_operaciones: formData.centro_operaciones
          ? formData.centro_operaciones.map((sucursal) => ({
              pais: sucursal.country,
              direccion: sucursal.address,
              telefonos: [sucursal.phone1, sucursal.phone2].filter(
                (tel) => tel && tel.trim() !== "",
              ),
              ubicacion:
                typeof sucursal.city === "string"
                  ? parseInt(sucursal.city, 10)
                  : sucursal.city,
              nombre_centro_operaciones: sucursal.name,
              email_facturacion_electronica_proveedor: sucursal.email
                ? [sucursal.email]
                : [],
            }))
          : [],
        correo_electronico: formData.correo,
        proveedor_calificado: formData.isProveedorCertificado || false,
        numero_identificacion: formData.numero,
      },
      asociado_negocio_formato_id: 1,
      informacion_bancaria_id:
        formData.informacion_bancaria_id ||
        (fetchedProveedorData && fetchedProveedorData.length > 0
          ? fetchedProveedorData[0].informacion_bancaria_id
          : 219),
      ciiu_id: formData.ciiu || null,
    };

    return payload;
  };

  // Función que se ejecuta al confirmar el guardado
  // Función que se ejecuta al confirmar el guardado
  const handleConfirmSave = async () => {
    try {
      console.log("Datos del formulario antes de transformación:", formData);

      // Transformamos formData a la estructura requerida
      const payload = transformFormDataToPayload(formData);
      console.log(
        "Payload final a enviar:\n",
        JSON.stringify(payload, null, 2),
      );

      // Extraer el ID de la información bancaria a actualizar
      const informacionBancariaId =
        formData.informacion_bancaria_id ||
        (fetchedProveedorData && fetchedProveedorData.length > 0
          ? fetchedProveedorData[0].informacion_bancaria_id
          : null);

      // Extraer los datos actualizados de la información bancaria,
      const datosActualizados = {
        detalle_metodo_pago: formData.detalle_metodo_pago.map(
          // eslint-disable-next-line no-unused-vars
          ({ id, ...rest }) => rest,
        ),
      };

      console.log(
        "Información bancaria ID a actualizar:",
        informacionBancariaId,
      );
      console.log(
        "Datos actualizados de información bancaria:",
        datosActualizados,
      );

      // Llamada 1: Actualizar la información bancaria
      const respInfoBancaria = await updateInformacionBancariaById(
        informacionBancariaId,
        datosActualizados,
      );
      console.log(
        "Respuesta de updateInformacionBancariaById:",
        respInfoBancaria,
      );

      // Llamada 2: Actualizar el asociado de negocio usando updateAsociadoDeNegocio
      const respAsociado = await updateAsociadoDeNegocio(
        payload.asociado_de_negocio_id, // Este valor se toma desde el payload
        payload,
        formData,
      );
      console.log("Respuesta de updateAsociadoDeNegocio:", respAsociado);

      /* alert("Datos enviados exitosamente"); */
      triggerAlert("dateSend", {});
      setShowFormVerify(false); // Cierra el modal de verificación
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
      alert("Error al enviar los datos. Revisa los campos.");
    }
  };

  const handleSearchResultsUpdate = (results) => {
    console.log("Resultados de la API recibidos en UpdateAsociados:", results);
    if (results && results.length > 0) {
      setFetchedProveedorData(results); // Guarda el JSON completo
      const esProveedor = results[0].asociado_negocio.es_proveedor;
      setFormData((prevData) => ({
        ...prevData,
        isProveedor: esProveedor,
      }));
    }

    // Aquí puedes realizar otras acciones con los resultados, o incluso pasarlos a otro callback si lo deseas
  };

  const handleTipoPersonaUpdate = (tipoPersona) => {
    console.log("Tipo de persona recibido en UpdateAsociados:", tipoPersona);

    // Actualiza el estado del formulario para marcar la casilla correspondiente
    if (tipoPersona === "NATURAL") {
      setFormData((prevData) => ({
        ...prevData,
        isNatural: true,
        isJuridica: false,
      }));
    } else if (tipoPersona === "JURIDICA") {
      setFormData((prevData) => ({
        ...prevData,
        isNatural: false,
        isJuridica: true,
      }));
    } else {
      // Opcional: si llega otro valor, puedes dejar ambos en false o mantener el estado actual
      setFormData((prevData) => ({
        ...prevData,
        isNatural: false,
        isJuridica: false,
      }));
    }
  };

  // Memoize components to prevent unnecessary re-renders
  const personaNaturalComponent = useMemo(
    () => (
      <PersonaNatural
        formData={formData}
        handleChange={handleChange}
        handleFormDataChange={handleFormDataChange}
        fetchedProveedorData={fetchedProveedorData} // NUEVA prop con el JSON recibido
        onTipoPersona={handleTipoPersonaUpdate} // Pasa la función para recibir tipo_persona
        onSearchResults={handleSearchResultsUpdate} // Pasa la función para recibir resultados
        nitProveedor={nit_proveedor} // Pasa el nit_proveedor como prop
      />
    ),
    [nit_proveedor, fetchedProveedorData],
  );

  const personaJuridicaComponent = useMemo(
    () => (
      <PersonaJuridica
        formData={formData}
        handleChange={handleChange}
        fetchedProveedorData={fetchedProveedorData} // NUEVA prop con el JSON recibido
        handleFormDataChange={handleFormDataChange}
        onTipoPersona={handleTipoPersonaUpdate} // Pasa la función para recibir tipo_persona
        onSearchResults={handleSearchResultsUpdate}
        nitProveedor={nit_proveedor} // Pasa el nit_proveedor como prop
      />
    ),
    [nit_proveedor, fetchedProveedorData],
  );

  const proveedorComponent = useMemo(
    () => (
      <Proveedor
        parentRef={formDateBasicRef}
        fetchedProveedorData={fetchedProveedorData}
        handleFormDataChange={handleFormDataChange}
        handleAdjuntosChange={handleAdjuntosChange} // Pasar función para actualizar adjuntos
      />
    ),
    [fetchedProveedorData],
  );
  const [showWelcomeBoard, setShowWelcomeBoard] = useState(false);

  if (showWelcomeBoard) {
    return (
      <HomeWelcomeBoard
        onClose={() => setShowWelcomeBoard(false)} // Vuelve a `Profile` cuando cierre
      />
    );
  }

  const [centro_operaciones, setSucursales] = useState([
    {
      id: 1,
      name: "",
      email: "",
      city: "",
      address: "",
      phone1: "",
      phone2: "",
      country: "",
      showSecondPhone: false, // propiedad para cada sucursal
    },
  ]);

  // Función para agregar una nueva sucursal
  const addNewRow = () => {
    const newRow = {
      id: Date.now(), // id único
      name: "",
      email: "",
      city: "",
      address: "",
      phone1: "",
      phone2: "",
      country: "",
      showSecondPhone: false, // propiedad para cada sucursal
    };
    setSucursales((prev) => {
      const newSucursales = [...prev, newRow];
      // Actualizamos formData de inmediato
      setFormData((prevData) => ({
        ...prevData,
        centro_operaciones: newSucursales,
      }));
      return newSucursales;
    });
  };

  // Función para eliminar una sucursal (similar a removeRow)
  const removeSucursal = (id) => {
    setSucursales((prevSucursales) =>
      prevSucursales.filter((sucursal) => sucursal.id !== id),
    );
  };

  // Función para renderizar las sucursales
  // Función centralizada para actualizar un campo de una sucursal
  const updateSucursalField = (id, field, value) => {
    setSucursales((prevSucursales) =>
      prevSucursales.map((sucursal) =>
        sucursal.id === id ? { ...sucursal, [field]: value } : sucursal,
      ),
    );
  };

  // Renderizado de las sucursales utilizando updateSucursalField en cada cambio
  const renderSucursales = () => {
    return centro_operaciones.map((row, index) => (
      <div key={row.id} className="sucursal-section">
        <h2>Sucursal {index + 1}</h2>
        <div className="two-column-grid">
          <TextField
            id={`name-sucursal-${row.id}`}
            label="Nombre"
            variant="outlined"
            className="input-textfield"
            required
            name="name"
            value={row.name}
            onChange={(e) =>
              updateSucursalField(row.id, "name", e.target.value)
            }
          />
          <Autocomplete
            className="input-form-control"
            disablePortal
            options={paises}
            // Se busca en el arreglo de países el que tenga el value igual a row.country
            value={paises.find((pais) => pais.value === row.country) || null}
            onChange={(event, value) => {
              updateSucursalField(row.id, "country", value?.value || "");
              // Llama a handleCountryChange para realizar tareas adicionales (como cargar ciudades)
              handleCountryChange(event, value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="País"
                className="input-select"
                required
                InputLabelProps={{ className: "input-label" }}
                InputProps={{
                  ...params.InputProps,
                  className: "input-form-control",
                }}
              />
            )}
          />
        </div>
        <div className="two-column-grid">
          <TextField
            id={`suc-email-${row.id}`}
            label="Correo"
            variant="outlined"
            className="input-textfield"
            required
            name="email"
            value={row.email}
            onChange={(e) =>
              updateSucursalField(row.id, "email", e.target.value)
            }
          />
          <Autocomplete
            freeSolo
            className="input-form-control"
            disablePortal
            options={ciudades}
            // Le decimos que, para cada opción, retorne la propiedad label (si es un objeto) o la opción directamente si es un string.
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.label
            }
            // Buscamos la opción en ciudades cuyo value coincida con row.city. Si no se encuentra, dejamos row.city o cadena vacía.
            value={
              ciudades.find((ciudad) => ciudad.value === row.city) ||
              row.city ||
              ""
            }
            onChange={(event, newValue) => {
              // Si newValue es un objeto, extraemos newValue.value; si es un string, lo usamos tal cual.
              updateSucursalField(
                row.id,
                "city",
                newValue
                  ? typeof newValue === "object"
                    ? newValue.value
                    : newValue
                  : "",
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ciudad"
                className="input-select"
                required
                InputLabelProps={{ className: "input-label" }}
                InputProps={{
                  ...params.InputProps,
                  className: "input-form-control",
                }}
              />
            )}
          />
        </div>
        <div className="three-column-grid">
          <TextField
            id={`direccion-sucursal-${row.id}`}
            label="Dirección"
            variant="outlined"
            className="input-textfield item-1"
            required
            name="address"
            value={row.address}
            onChange={(e) =>
              updateSucursalField(row.id, "address", e.target.value)
            }
          />
          <div
            className={`phone-input-containervis ${
              row.showSecondPhone ? "visible item-2" : "invisible item-2"
            }`}
          >
            <TextField
              id={`second-phone-profile-${row.id}`}
              label="Teléfono 2"
              variant="outlined"
              className="input-textfield"
              type="text"
              inputProps={{ maxLength: 15 }}
              name="phone2"
              value={row.phone2}
              onChange={(e) =>
                updateSucursalField(row.id, "phone2", e.target.value)
              }
            />
          </div>

          <div className="phone-input-container item-3">
            <TextField
              id={`phone-profile-${row.id}`}
              label="Teléfono"
              variant="outlined"
              className="input-textfield responsive-textf-tel"
              required
              type="text"
              inputProps={{ maxLength: 15 }}
              name="phone1"
              value={row.phone1}
              onChange={(e) =>
                updateSucursalField(row.id, "phone1", e.target.value)
              }
            />
            <div className="add-phone-button-container">
              <Fab
                size="small"
                sx={{
                  backgroundColor: "#FCB10B",
                  color: "white",
                  "&:hover": { backgroundColor: "#E0A009" },
                }}
                aria-label="add"
                onClick={() =>
                  updateSucursalField(
                    row.id,
                    "showSecondPhone",
                    !row.showSecondPhone,
                  )
                }
                className="add-phone-button"
              >
                <AddIcon />
              </Fab>
            </div>
          </div>
        </div>
        {centro_operaciones.length > 1 && (
          <IconButton
            color="error"
            onClick={() => removeSucursal(row.id)}
            title="Eliminar fila"
          >
            <RemoveCircleIcon />
          </IconButton>
        )}
      </div>
    ));
  };
  // Cada vez que cambie el estado local de sucursales, se actualiza formData.sucursales
  useEffect(() => {
    console.log("Actualizando formData.sucursales:", centro_operaciones);
    setFormData((prevData) => ({
      ...prevData,
      centro_operaciones: centro_operaciones,
    }));
  }, [centro_operaciones, setFormData]);

  return (
    <div className="creation-form-container">
      <div className="form-header">
        <p>
          {" "}
          <strong>{company?.name}</strong>
        </p>
        <h1 className="form-title">Actualizar asociados de negocio</h1>

        <Link to="/HomeWelcomeBoard">
          <IconButton aria-label="back" className="back-button">
            <KeyboardReturnIcon />
          </IconButton>
        </Link>
      </div>
      <form className="form-content" onSubmit={handleSave}>
        <div ref={formDateBasicRef} className="form-date-basic">
          <div className="person-type">
            <span className="person-type-label">Persona:</span>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={formData.isNatural}
                onChange={handleNaturalChange}
              />
              <span className="checkbox-text">Natural</span>
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={formData.isJuridica}
                onChange={handleJuridicaChange}
              />
              <span className="checkbox-text">Jurídica</span>
            </label>
          </div>
          {formData.isNatural
            ? personaNaturalComponent
            : personaJuridicaComponent}
          <div className="sucursal-section">
            {renderSucursales()}
            <Button
              variant="contained"
              onClick={addNewRow}
              sx={{ textTransform: "none" }}
              className="save-button mq2"
            >
              Agregar Sucursal
              <AddCircleOutlineIcon />
            </Button>
            {formData.isProveedor && showArrowIcon && (
              <div className="icon-arrowdown-aso" onClick={scrollToProveedor}>
                <ArrowDownIcon />
              </div>
            )}
          </div>
          {formData.isProveedor && (
            <div ref={proveedorRef}>{proveedorComponent}</div>
          )}
        </div>
        <div className="bottom-content">
          <div className="bottom-checkboxes">
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={formData.isEmpleado}
                onChange={(e) =>
                  handleCheckboxChange("isEmpleado", e.target.checked)
                }
              />
              <span className="checkbox-text">Empleado</span>
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={formData.isCliente}
                onChange={(e) =>
                  handleCheckboxChange("isCliente", e.target.checked)
                }
              />
              <span className="checkbox-text">Cliente</span>
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={formData.isProveedor}
                onChange={(e) =>
                  handleCheckboxChange("isProveedor", e.target.checked)
                }
              />
              <span className="checkbox-text">Proveedor</span>
            </label>
          </div>
        </div>
        <Alert />
        <div className="save-button-container">
          <Button
            variant="contained"
            type="button"
            className="save-button"
            sx={{ textTransform: "none" }}
            onClick={handleSave} // Abre FormVerify
            disabled={!isSaveEnabled}
          >
            <SaveAltIcon />
            Guardar
          </Button>
        </div>
        <div className="save-button-container2-clean">
          <Button
            variant="contained"
            type="button"
            className="save-button"
            sx={{ textTransform: "none" }}
            onClick={() => {
              window.location.href = "/FormUpdateAsociados";
            }}
          >
            Limpiar
          </Button>
        </div>

        {showFormVerify && (
          <FormVerify
            formDataContent={
              <AsociadosVerify
                formData={formData}
                documentData={documentData}
              />
            }
            onClose={() => setShowFormVerify(false)} // Cierra FormVerify
            onConfirm={handleConfirmSave} // Envía los datos al backend
          />
        )}
      </form>
    </div>
  );
};

export default UpdateAsociados;
